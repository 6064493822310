import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "reactstrap";
import './Welcome.css';
import './welecomepage2.css';

const WelcomePage2 = () => {
    const navigate = useNavigate();
    const [showSpinner, setShowSpinner] = useState(true);
    const [progress, setProgress] = useState(0);

    const [loading, setLoading] = useState(true);

    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpacity(1);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // Wait for 3 seconds

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSpinner(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const totalTime = 5000;
        const updateInterval = 100;
        const increment = (updateInterval / totalTime) * 100;

        const timer = setInterval(() => {
            setProgress((prevProgress) => {
                const updatedProgress = prevProgress + increment;
                if (updatedProgress >= 100) {
                    clearInterval(timer);
                    navigate('/welcomePage');
                    return 100;
                }
                return updatedProgress;
            });
        }, updateInterval);

        return () => clearInterval(timer);
    }, [navigate]);

    const Parentdiv = {
        height: "2px",
        width: '100%',
        backgroundColor: "gray",
        borderRadius: 40,
    }

    const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: "#CA40Cf",
        borderRadius: 40,
        textAlign: 'right',
    }



    return (
        <div className='mainSection' >
            {loading ? (
                <div id="loader"></div>
            ) : (
                <div id="myDiv" className="animate-bottom" style={{ display: 'block' }}>
                </div>
            )}


            {showSpinner ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spinner color="light">Loading...</Spinner>
                </div>
            ) : (
                <div  className="fade-in" >
                    <img
                        alt="..."
                        // className="img-raised"
                        src={require("assets/img/Okay (1).png")}
                    />
                </div>
            )}

            <div className='main-text'>
                <h1>Unleashing the possibilities with <span style={{ fontSize: '38px', fontWeight: '550' }}>Generative AI</span></h1>
                    <div style={Parentdiv}>
                        <div style={Childdiv}>
                    </div>
                </div>

            </div>
            <div className='main-img' >
                <img
                    alt="..."
                    className="img-raised"
                    src={require("assets/img/Voice.png")}
                    style={{
                        opacity: opacity,
                        transition: 'opacity 5s ease-in',
                        marginLeft:'55%'
                    }}
                />
                <div className="glassmorphic-box" style={{
                    opacity: opacity,
                    transition: 'opacity 2s ease-in',
                    fontWeight:'400', fontSize:'16px', fontFamily:'Space Grotesk', color:'rgba(245, 245, 245, 1)'
                }}>
                    <p style={{}}>Here is the list of shoes under 5000.</p>
                    <ul>
                        <li>Nike Air Max</li>
                        <li>Adidas Ultraboost</li>
                        <li>Converse All Star</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default WelcomePage2;
