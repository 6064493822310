
import React, { useState } from 'react';
// reactstrap components
import { TabContent, } from "reactstrap";
import './Card.css'

export default function Tabs() {
  const [animate, setAnimate] = useState(false);

  const handleClick = () => {
    setAnimate(true);
    setTimeout(() => setAnimate(false), 2000);
  };


  return (
    <div className={`Why ${animate ? 'animate' : ''}`} style={{ height: '100%', width: '100%', fontFamily: 'Space Grotesk', }}>

      <div className='why-section'>
        <h1 className='header' >Why Us ?</h1>
        <h4 className='header-text'>
          We are a team dedicated to revolutionizing how brands understand and engage with their customers. Through the use of advanced AI technology, we provide insights into customer interactions, helping businesses build trust and drive positive change. With our proprietary AI platform, we work alongside your team to ensure a seamless transition to a data-driven approach, empowering you to better serve your customers.
          Our AI development initiatives receive robust support from industry leaders Nvidia and Microsoft, ensuring excellence span across all CX and AI development services.
        </h4>
      </div>


      <div
      className='card-section'>
        <div
          className="border-box" >
          <div style={{ display: 'flex' }}>
            <p className='card-nub'>
              15
            </p>
            <p
            className='card-head' >
              Project <br /> Delivered
            </p>
          </div>
          <p className='card-para'
          style={{ display: 'grid', fontSize: '20px', fontWeight: '400', color: '#8E8E8E' }}>
            We have successfully delivered a large project with a TPS (transactions per second) size exceeding 1000 TPS and generating billions of requests per day. The majority of these projects involve enterprise and business-critical applications, all designed, developed, and delivered by our team of technology experts along with managed services.
          </p>
        </div>



        <div
          className="border-box"
        >
          <div style={{ display: 'flex' }}>
            <p
            className='card-nub'
              style={{
                fontSize: "72px",
                color: '#864DE2',
                fontWeight: '600',
                width: '25%',
                height: 'auto',
                // marginLeft: '10px',

              }}
            >
              25
            </p>
            <p
            className='card-head'
              style={{
                fontSize: "36px",
                color: "#FFFFFF",
                fontWeight: '400',
                width: '75%',
                height: 'auto',
                marginLeft: '10%',
                // border:'2px solid red',
                textAlign: 'start',
                marginTop: '15px',

              }}
            >
              Happy <br />Customers
            </p>
          </div>
          <p 
          className='card-para'
          style={{ display: 'flex', flexDirection: 'column', fontSize: '20px', fontWeight: '400', color: '#8E8E8E',}}>
            Our proprietary cutting edge AI CX platform helps a leading healthcare company maximize team performance and productivity with our AI Agent solution. They were able to reduce their operational expenses by 70% within 6 months of implementing our platform.
          </p>
        </div>
        <div
          className="border-box"
        >
          <div style={{ display: 'flex' }}>
            <p
            className='card-nub'
              style={{
                fontSize: "72px",
                color: '#8062F8',
                fontWeight: '600',
                width: '25%',
                height: 'auto',


              }}
            >
              60
            </p>
            <p
            className='card-head'
              style={{
                fontSize: "36px",
                color: "#FFFFFF",
                fontWeight: '400',
                width: '75%',
                height: 'auto',
                marginLeft: '10%',
                // border:'2px solid red',
                textAlign: 'start',
                justifyContent: 'center',
                marginTop: '15px',

              }}
            >
              On going <br />  Project
            </p>
          </div>
          <p 
          className='card-para'
          style={{ display: 'flex', flexDirection: 'column', fontSize: '20px', fontWeight: '400', color: '#8E8E8E' }}>
            We have over 60 projects across different teams specializing in CCaaS, AI chatbots, and AI agents dedicated to Natural Language Processing (NLP) and Sentiment Analysis for enterprise. Our cutting-edge proprietary new age AI technologies development expertise drive all current projects, and we endeavours to cater to our customers needs like never before.
          </p>
        </div>
      </div>

    </div>




  );
}
