import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
// reactstrap components
import {
  Card,
  CardBody,
  Collapse,
} from 'reactstrap';

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import TrustedUsers from 'views/IndexSections/TrustedUsers';
import "./ChatBot.css";

const ChatBot =() => {
  const [openId, setOpenId] = useState(null);

  const toggle = (id) => {
    if (openId === id) {
      setOpenId(null);
    } else {
      setOpenId(id);
    }
  };
  const navigate = useNavigate();

  const navigateAi = () => {
    navigate("/ai-agent-development-company")
    window.location.reload();
  }

  const sentimentAnalysis = () => {
    navigate("/sentiment-analysis-development-company")
    window.location.reload();
  }

  const generativeAi = () => {
    navigate("/generative-ai-development-company")
    window.location.reload();
  }

  function handleClick() {
    const purpose = "Become-Partner"
    localStorage.setItem("Purpose", purpose)
    navigate("/contact-us");
  }

  useEffect(() => {
    // Set the document title
    document.title = 'Leading Chatbot Development Company | Chatbot Solutions';
  
  }, []);



  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
        <ExamplesNavbar />
          <div className="ChatBot-header">
            <div className='ChatBot-main' >
              <h1 className='ChatBot-heading'>
                Chat <br />
                <span>Bot</span>
              </h1>
              <div className='ChatBot-img'>
                <img
                className='Chatbot-mid-img'
                  alt="..."
                  src={require("assets/img/Rectangle 13 (8).svg").default}
                /></div>
              <div className='ChatBot-box'>
                <p className='ChatBox-box-para'>We simplify chatbot development, offering scalability to seamlessly grow with your business, effortless integration with your current platform, and powerful NLP (Natural Language Processing) for intelligent interactions. This translates to faster development, cost-effective solutions, and exceptional customer experiences that perfectly reflect your brand.</p>
              </div>
              <img
              className='ChatBot-side-img'
                alt="..."
                src={require("assets/img/Rectangle 11 (6).svg").default}
              />
            </div>
            
          </div>

          <div className='ChatBot-second' >
            <div className='ChatBot-second-main'>
              {/* Box 1 */}
              <div className='ChatBot-second-box1'>
                <h1 className='ChatBot-second-box1-h1'>What is Chatbot?</h1>
                <img
                  alt="..."
                  src={require("assets/img/Group.svg").default}
                  className='ChatBot-second-box1-img'
                
                />
                <img
                  alt="..."
                  src={require("assets/img/Frame 48 (1).svg").default}
                  className='ChatBot-second-box1-img2'
                />
              </div>

              {/* Box 2 */}
              <div className='ChatBot-second-box2'>
                <p className='ChatBot-second-box2-para'>
                  A chatbot is a virtual conversation partner that simulates human interaction through text or voice messages. Imagine a digital assistant readily available to answer your questions, troubleshoot problems, or even place orders. Chatbots are powered by artificial intelligence (AI) that allows them to understand user queries and respond in a natural, engaging way. They're becoming increasingly common across various industries, from customer service on websites to providing information on mobile apps.
                </p>
                <br />
                <p className='ChatBot-second-box2-para'>
                  We redefine the landscape of virtual communication, offering unparalleled expertise in crafting intelligent chatbots tailored to meet your unique needs. With a focus on innovation and customer satisfaction, we pride ourselves on delivering the Best Chatbot Development Solutions that seamlessly integrate with your business framework. Whether you're looking to enhance customer support, streamline operations, or engage users in dynamic conversations, our team of seasoned professionals is dedicated to turning your vision into reality. Experience the future of conversational AI with Telemo, where excellence meets innovation.
                </p>

              </div>
            </div>
          </div>

      <TrustedUsers />


          <div className='ChatBot-forth'>
            <h1 className='ChatBot-forth-h1' > Why to Choose Our <br /> Chatbot Development Services </h1>

          </div>


          <div className='ChatBot-fifth' >
            {/* Box 1 */}
            <div className='ChatBot-fifth-box1'>
              <img
                alt="..."
                src={require("assets/img/Polygon 1.png")}
              />
              <h1 className='ChatBot-fifth-box1-h1'>Improved Customer Service</h1>
              <p className='ChatBot-fifth-box1-para' style={{ width: '90%', fontSize: '20px', color: '#DADADA', fontWeight: '400', marginTop: '-2%' }}>Our Chatbot Development Services are designed to revolutionize the way you interact with your customers. With Telemo chatbots, you can offer 24/7 support to your customers, ensuring that their queries are addressed promptly and accurately. Our advanced algorithms enable our chatbots to understand natural language, allowing them to engage in meaningful conversations and provide personalized assistance. </p>
            </div>

            {/* Box 2 */}
            <div className='ChatBot-fifth-box2'>
              <img
                alt="..."
                src={require("assets/img/Polygon 1.png")}
              />

              <h1 className='ChatBot-fifth-box2-h1'>Increased Sales</h1>
              <p className='ChatBot-fifth-box2-para' >Drive sales and revenue growth with Telemo chatbot solutions. Our chatbots are not just virtual assistants; they are powerful sales tools designed to boost your bottom line. By providing customers with information about products and services and guiding them through the purchasing process, our chatbots help increase sales conversions.</p>
            </div>

            {/* Box 3 */}
            <div className='ChatBot-fifth-box3'>
              <img
                alt="..."
                src={require("assets/img/Polygon 1.png")}
              />
              <h1 className='ChatBot-fifth-box3-h1' >Reduced Costs</h1>
              <p className='ChatBot-fifth-box3-para' >We believe in maximizing efficiency and minimizing costs. That's why our chatbot development services are designed to automate manual tasks, saving you time and money. By automating routine inquiries and processes, our chatbots free up your team to focus on more strategic tasks, improving overall productivity. With Telemo, you can streamline operations and reduce overhead costs.</p>
            </div>

            {/* Box 4 */}
            <div className='ChatBot-fifth-box4'>
              <img
                alt="..."
                src={require("assets/img/Polygon 1.png")}
              />

              <h1 className='ChatBot-fifth-box4-h1'>Enhanced Data Collection</h1>
              <p className='ChatBot-fifth-box4-para' >Our chatbots are not just conversation starters; they are valuable data collection tools. By interacting with customers and gathering information about their preferences, behaviors, and needs, our chatbots provide valuable insights that can inform your business decisions. From identifying market trends to predicting customer demand, the data collected by our chatbots enables you to stay ahead of the curve.</p>
            </div>


            {/* Box 5 */}
            <div className='ChatBot-fifth-box5'>
              <img
                alt="..."
                src={require("assets/img/Polygon 1.png")}
              />
              <h1 className='ChatBot-fifth-box5-h1'>Scalability and Flexibility</h1>
              <p className='ChatBot-fifth-box5-para' >Chatbot Development Services offer unparalleled scalability and flexibility. Whether you're a small startup or a large enterprise, our chatbots can adapt to your changing needs and scale seamlessly as your business grows. Whether you need a simple customer service bot or a complex virtual assistant, Telemo has you covered.</p>
            </div>

            {/* Box 6 */}
            <div className='ChatBot-fifth-box6' >
              <img
                alt="..."
                src={require("assets/img/Polygon 1.png")}
              />

              <h1 className='ChatBot-fifth-box6-h1' >Improved User Engagement</h1>
              <p  className='ChatBot-fifth-box6-para'>By delivering relevant content, proactive assistance, and engaging conversations, our chatbots foster deeper connections with your audience, driving user engagement and loyalty. Whether you're looking to increase website traffic, boost app retention, or enhance brand loyalty, our chatbots can help you achieve your goals.</p>
            </div>


          </div>
          <div className='ChatBot-sixth'>
            <img
              alt="..."
              src={require("assets/img/Okay (2).png")}
              style={{
                width: '70%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </div>

          <div className='ChatBot-seventh'>
            <h1 className='ChatBot-sev-h1'> AI Chatbot <br /> Development Services We Offer</h1>
            <div className='ChatBot-sev-main'>
              {/* Box 1 */}


              <div className='ChatBot-sev-box1' >
                <div>
                  <div color="primary" onClick={() => toggle(1)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400' ,cursor: 'pointer',}}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                      <span>Custom Chatbot Development</span>
                      <span>{openId === 1 ? '-' : '+'}</span>
                    </div>
                  </div>
                  <Collapse isOpen={openId === 1}>
                    <Card>
                      <CardBody>
                      <ul className='bullet-remove'>
                        <li>Our team of experienced chatbot developers will work closely with you to understand your specific requirements and design a custom chatbot that perfectly aligns with your business goals. We'll consider factors such as customer persona, communication channels, and desired functionality to create a chatbot that seamlessly integrates into your existing ecosystem.</li>
                      </ul>
                      </CardBody>
                    </Card>
                  </Collapse>
                  <div style={{
                    // backgroundColor: 'white',
                    width: '100%',
                    border: '1px solid',
                    borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                    borderImageSlice: 1
                  }} />
                </div>

                <div>
                  <div color="primary" onClick={() => toggle(2)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400' ,cursor: 'pointer',}}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                      <span>   Chatbot Integration</span>
                      <span> {openId === 2 ? '-' : '+'}</span>
                    </div>

                  </div>
                  <Collapse isOpen={openId === 2}>
                    <Card>
                      <CardBody>
                      <ul className='bullet-remove'>
                        <li>Whether you have a website, mobile app, or other communication platform, we can seamlessly integrate your chatbot to provide a consistent and unified customer experience. Our integration expertise ensures that your chatbot functions flawlessly within your existing systems, without disrupting user workflows.</li>
                      </ul>
                      </CardBody>
                    </Card>
                  </Collapse>
                  <div style={{
                    width: '100%',
                    border: '1px solid',
                    borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                    borderImageSlice: 1
                  }} />
                </div>

                <div>
                  <div color="primary" onClick={() => toggle(3)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400' ,cursor: 'pointer',}}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                      <span>Chatbot Training</span>
                      <span> {openId === 3 ? '-' : '+'}</span>
                    </div>

                  </div>
                  <Collapse isOpen={openId === 3}>
                    <Card>
                      <CardBody>
                      <ul className='bullet-remove'>
                        <li>To ensure your chatbot delivers intelligent and engaging interactions, we provide comprehensive training services. Our team will utilize advanced machine learning techniques to train your chatbot on a vast dataset of conversations, enabling it to understand natural language, respond accurately, and adapt to diverse customer queries.</li>
                      </ul>
                      </CardBody>
                    </Card>
                  </Collapse>
                  <div style={{
                    // backgroundColor: 'white',
                    width: '100%',
                    border: '1px solid',
                    borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                    borderImageSlice: 1
                  }} />
                </div>
                <div>
                  <div color="primary" onClick={() => toggle(4)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400',cursor: 'pointer', }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                      <span> Chatbot Maintenance</span>
                      <span> {openId === 4 ? '-' : '+'}</span>
                    </div>

                  </div>
                  <Collapse isOpen={openId === 4}>
                    <Card>
                      <CardBody>
                      <ul className='bullet-remove'>
                        <li>We understand that your chatbot is an evolving entity, and we're committed to providing ongoing maintenance and support to ensure it remains up-to-date, functional, and aligned with your evolving business needs. Our maintenance services include performance monitoring, bug fixes, feature enhancements, and continuous training to keep your chatbot at the forefront of chatbot technology.</li>
                      </ul>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>

              </div>

              {/* Box 2 */}
              <div className='ChatBot-sev-box2'>
                <img
                  alt="..."
                  src={require("assets/img/Landing Page 1.svg").default}
                  style={{
                    width: '95%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </div>
            </div>
          </div>
          <div className='CC-partner-section-img1'
       >
          <img
            style={{
              width: '43%',
              height: '50%',
            }}
            alt="..."
            src={require("assets/img/Vector.svg").default}
          />
        </div>
        <div className='CC-partner-section-img2'>
        <img
          style={{
            width: '10%',
            height: '50%',
            margin: '-36% 0% 0% 0%'

          }}
          alt="..."
          src={require("assets/img/Ellipse 7.svg").default}

        />
      </div>

      <div className='CC-partner-section-img3'>
        <img
          style={{
            width: '15%',
            height: '50%',
             margin: '-33% 0% 0% 0%'
          }}
          alt="..."
          src={require("assets/img/Ellipse 6.svg").default}
        />
      </div>


      <div className="CC-Partner-Section">
        <div className="CC-first-section">

          <h1>
            Become a Partner
          </h1>
          <div className="glassmorphism" style={{ width: '82%', padding: '20px' }} >
            <p style={{ textAlign: 'start', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '400' }}>
              We're actively pursuing partnerships on a global scale. Collaborating with us means forging a strategic alliance aimed at revolutionizing customer experiences and spearheading transformative initiatives for enterprises worldwide. As our esteemed partner, you'll unlock access to state-of-the-art AI technology and a dedicated team fully devoted to your triumph. Together, we'll co-create tailor-made solutions grounded in feedback, unleashing the full potential of your brand, elevating customer satisfaction to unprecedented levels, and driving substantial revenue growth.
            </p>
            <button className="button-bts bts" onClick={handleClick} >
              <img alt="Handshake icon" className="path" src={require("assets/img/Vector (5).svg").default} />
              <span style={{ marginLeft: '5px', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '500', color: 'rgba(255, 255, 255, 0.8)' }}>Become a Partner</span>
            </button>
          </div>
        </div>

        <div className="second-section">
          <img
            alt="..."
            src={require("assets/img/Frame 147.svg").default}
            style={{
              width: '100%',
              height: '60%',
              objectFit: 'cover',
              borderRadius: '20px'
            }}
          />
        </div>
      </div>
      <div className='Explore-main'>
        <div className='Explore-first'>
          <button className='Explore-btn'

          >
            OUR PRODUCTS
          </button>

          <h1 className='explore-h1'>Explore Our <span style={{ fontFamily: 'Playfair Display' }}>Other</span>  Product</h1>
        </div>


        {/* Box 1 */}
        <div className='explore-box1'>

          <img
            alt="..."
            src={require("assets/img/AI-agent1.svg").default}
            onClick={navigateAi}
            className='box1-img'
          />

        </div>

        <div className='explore-box2'>
          {/* Box 1 */}
          <div className='box2-img1'>

            <img
              className='img1-a'
              alt="Box 2 Image"
              src={require("assets/img/Sentiment.svg").default}
              onClick={sentimentAnalysis}
            />
          </div>

          {/* Box 2 */}
          <div className='box2-img2'>
            <img
              className='img2-a'
              alt="Box 1 Image"
              src={require("assets/img/Generative AI.svg").default}
              onClick={generativeAi}
            />
          </div>
        </div>

      </div>
          <Footer />
    </>
  );
}

export default ChatBot
