import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import './page.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PopupWidget } from "react-calendly";
import { Bars, ColorRing, ThreeDots } from "react-loader-spinner";
import { AiOutlineClose } from "react-icons/ai";
const slides = [
  "Generative AI",
  "Voice Bots",
  "Chat Bots",
  "AI Agent"
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  
};

const PageHeader = (args) => {
  const navigate = useNavigate();
  const [modal1, setModal1] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setModal1(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  // Function to handle "Cancel" button click
  const handleCancels = () => {
    setModal1(false); // Close the modal
  };
  const [currentSlide, setCurrentSlide] = useState(0);
  const [animationState, setAnimationState] = useState('entering');




  useEffect(() => {
    setAnimationState('entering');
    const timer = setTimeout(() => {
      setAnimationState('exiting');
    }, 3000);

    return () => clearTimeout(timer);
  }, [currentSlide]);




  useEffect(() => {
    if (animationState === 'exiting') {
      const timer = setTimeout(() => {
        setCurrentSlide((prevSlide) =>
          prevSlide === slides.length - 1 ? 0 : prevSlide + 1
        );
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [animationState]);


  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  // const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [emailflag, setEmailFlag] = useState(true);

  const handleChanges = (event) => {
    const { value } = event.target;
    setEmail(value);
    setSuccess('');
    validateEmail(value);
  };

  const validateEmail = (value) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!value) {
      setError('Please enter your email.');
      setEmailFlag(false);
      return false;
    } else if (!emailPattern.test(value)) {
      setError('Invalid email address.');
      setEmailFlag(false);
      return false;
    }
    setError('');
    setEmailFlag(true);
    return true;
  };

  // useEffect(() => {
  //   ReadSubscriberData();
  // }, []);

  // const ReadSubscriberData = () => {
  //   fetch("https://script.google.com/macros/s/AKfycbzOJwNVds4KKHaKwP1wD1gsUUWXHf2N76N2-3M75UMn7yqf4GZ2e-N2ighCcaMVdh7p/exec")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setCount(data.length + 36);
  //     });
  // };

  // const CreateSubscriberData = (event) => {
  //   event.preventDefault();

  //   if (!email) {
  //     toast("Please enter email address", {
  //       position: "top-right",
  //       autoClose: 2000,
  //     });
  //     return;
  //   }

  //   if (!emailflag) {
  //     toast("Please enter valid email", {
  //       position: "top-right",
  //       autoClose: 2000,
  //     });
  //     return;
  //   }

  //   setLoading(true);

  //   fetch("https://sheetdb.io/api/v1/psmjelcda1617", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       data: [
  //         {
  //           SNo: "INCREMENT",
  //           EmailId: email,
  //         },
  //       ],
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setEmail("");
  //       ReadSubscriberData();
  //       toast.success('Email submitted successfully!', {
  //         position: "top-right",
  //         autoClose: 2000,
  //       });


  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //       toast.error('Failed to submit email.', {
  //         position: "top-right",
  //         autoClose: 2000,
  //       });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };
  // const [count, setCount] = useState(() => {
  //   const savedCount = localStorage.getItem('submissionCount');
  //   return savedCount ? Math.min(parseInt(savedCount, 10), 500) : 36;
  // });



  // const ReadSubscriberData = async () => {
  //   try {
  //     const response = await fetch("https://script.google.com/macros/s/AKfycbzOJwNVds4KKHaKwP1wD1gsUUWXHf2N76N2-3M75UMn7yqf4GZ2e-N2ighCcaMVdh7p/exec");
  //     const formData = await response.json();
  //     // setCount(formData.length + 36);
  //     console.log(formData.length ,"------------------------------");
      
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };
  

  const [count, setCount] = useState(0);

  


  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };
  
  useEffect(() => {
    fetchCount();
  }, []);

  const fetchCount = async () => {
    const url = 'https://script.google.com/macros/s/AKfycbxm74TPUFs-5MPkoa7z4sOcVfn-g3qpypT3zbtgCHELY3OJviRQFcB9LLASr8eKbnVWwA/exec';
    
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const result = await response.json();
      // console.log(`Fetched result: ${JSON.stringify(result)}`);
      if (result.totalEntries !== undefined) {
        setCount(result.totalEntries);
        // console.log(`Updated count state to: ${result.totalEntries}`);
      } else {
        console.error('Unexpected structure of count.');
      }
    } catch (error) {
      console.error('Error retrieving count:', error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!email) {
      toast("Please enter an email address", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
  
    if (!emailflag) {
      toast("Please enter a valid email address", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
  
    setLoading(true);
  
    const url = 'https://script.google.com/macros/s/AKfycbxm74TPUFs-5MPkoa7z4sOcVfn-g3qpypT3zbtgCHELY3OJviRQFcB9LLASr8eKbnVWwA/exec';
    const formData = new FormData();
    formData.append('email', email);
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const result = await response.json();
  
      if (result.message) {
        toast.success('Email submitted successfully!', {
          position: "top-right",
          autoClose: 2000,
        });
  
        fetchCount(); // Fetch updated count
  
        setEmail(''); // Clear the email input field
      }
      
    } catch (error) {
      console.error('Error:', error);
      toast.error('Submission failed. Please try again.', {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };
  
  

  
  
  
  


  return (
    <>
      <div className="PageHeader" >


        <Modal className='show-modal' isOpen={modal1} toggle={handleCancels} {...args}>
          <div className='sub-head' >
            <div className='right-img'>

            </div>
         

            <div className='sub-background' >
              <p className='main-heading' ><span >Get Ready for the Future of <span style={{ fontWeight: '500' }}>Customer Experience!</span></span></p>

              <p className='first_para'>Our AI-first CX platform is coming soon! Be among the first 100 to sign up and get 3 months of free access and support!</p>
              <p className='sec_para'  >Over <span style={{ fontWeight: 'bold', fontSize: '16px' }}>  {count + 25}</span> people already subscribed</p>

              <div style={{ width: '100%', marginLeft: '1%' }}>
                <form className='form-submit'
                 onSubmit={handleSubmit}
                 >
                  <input
                    type="email"
                    placeholder='Enter your email'
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    className='sub_input'
                
                  />

                  <button
                    type="submit"
                    disabled={loading}
                    className='sub_btn'
                    style={{

                      opacity: loading ? 0.6 : 1,
                      pointerEvents: loading ? 'none' : 'auto',
                    }}
                  >
                    {loading ? (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Bars
                          height="15"
                          width="30"
                          color="white"
                          ariaLabel="bars-loading"
                          visible={true}
                        />
                      </div>
                    ) : (
                      'Subscribe'
                    )}
                  </button>
                </form>
                {error && <div style={{ color: 'red', marginLeft: '3%', fontSize: '12px', textAlign: 'start', width: '50%' }}>{error}</div>}
                {success && <div style={{ color: 'green', marginLeft: '3%', fontSize: '12px', textAlign: 'start', width: '50%' }}>{success}</div>}
              </div>
            </div>

          </div>
        </Modal>


        <div className='mid-img'>
          <img
            style={{
              width: '100%',
              height: '70%',
            }}
            alt="..."
            src={require("assets/img/Frame 56.png")}
          />
        </div>
        <div className='Page-text'
        >
          <h1 className='heading-text' style={{ fontSize: '88px', fontFamily: 'Space Grotesk', fontWeight: '300' }}>
            Create better <span style={{ fontWeight: '500' }}>Business</span> with
          </h1>
        </div>
        <div className="slideshow-container">
          <div className={`slide ${animationState}`} style={{ fontFamily: 'Space Grotesk' }}>
            {slides[currentSlide]}
          </div>
        </div>
        <div className='border-text'>
          <div style={{
            position: 'relative',
            width: '100%',
            height: '80%'
          }}>
            <img
              style={{
                width: '100%',
                height: '100%',
                display: 'block',
              }}
              alt="..."
              src={require("assets/img/Subtract (1).png")}
            />
            <p className='b-text'>
              Do you know what's really happening with your customers? Do you want answers to these questions? Do you want to understand how interactions are happening between your customers and your brand?
              We are here to provide you with answers, and our AI will assist you in listening to and understanding customer interactions.
            </p>

            <PopupWidget
              url="https://calendly.com/amit-ranjan-startelelogic"
              rootElement={document.getElementById("root")}
              text="Get Demo"
              textColor="#ffffff"
              color="#4C19A1"
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default PageHeader


