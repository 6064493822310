import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import {
  Button,
} from "reactstrap";
import {
  Card,
  CardBody,
  Collapse,
} from 'reactstrap';
import './ContactCenter.css'

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import TrustedUsers from 'views/IndexSections/TrustedUsers';


const ContactCenter =() => {

  const [openId, setOpenId] = useState(null);

  const toggle = (id) => {
    if (openId === id) {
      setOpenId(null);
    } else {
      setOpenId(id);
    }
  };

  const navigate = useNavigate();

  const navigateAi = () => {
    navigate("/ai-agent-development-company")
    window.location.reload();
  }

  const sentimentAnalysis = () => {
    navigate("/sentiment-analysis-development-company")
    window.location.reload();
  }

  const generativeAi = () => {
    navigate("/generative-ai-development-company")
    window.location.reload();
  }


  function handleClick() {
    const purpose = "Become-Partner"
    localStorage.setItem("Purpose", purpose)
    navigate("/contact-us");
  }

  useEffect(() => {
    // Set the document title
    document.title = 'Top Contact Center Development Company | Expert Solutions';

  }, []);


 

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
 
  return (
    <>
      <ExamplesNavbar />
        <div className="contact-center-header">
          <div className='contact-center-main'>
            <h1  className='CC-header'>
              Contact <br />
              <span>Center</span>
            </h1>
            <div className='CC-img'>
              <img
                style={{ width: '50%', }}
                alt="..."
                src={require("assets/img/Rectangle 13.svg").default}
              /></div>
            <div className='CC-box'>
              <p className='CC-box-para'>Contact Centers offer a variety of features to enhance customer interactions, including omnichannel support for seamless communication across channels, CRM integration for informed agents, self-service options for customer empowerment, analytics and reporting for data-driven decisions, AI-powered features for improved service, robust security to safeguard information, scalability to adapt to business growth, and cost-effectiveness for efficient operations.</p>
            </div>
            <img
            className='main-side-img'
              alt="..."
              src={require("assets/img/Rectangle 11.svg").default}
            />
            <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
              {/* <img
            //  className="rotate-once"
              style={{ width: '30%',marginTop: '-42%' ,
              marginLeft:'0%',
              objectFit: 'cover',
              borderRadius: '20px'}}
              alt="..."
              // src={require("assets/img/Vector (6).png")}
           
            /> */}
            </div>
          </div>
        </div>



        <div className='CC-second-section'>
          <div className='second-main'>
            {/* Box 1 */}
            <div className='second-box1'>
              <h1 className='second-box1-h1'>What is Contact  <br />Center ?</h1>
              <img
                alt="..."
                src={require("assets/img/Rectangle 16.svg").default}
                className='second-box1-img'
             />
            </div>

            {/* Box 2 */}
            <div className='second-box2'>
              <p className='box2-para1'>
                A contact center is essentially a centralized hub that manages all a company's customer interactions. A contact center is a group of representatives that deal with all incoming and outgoing customer communication via phone calls, social media, live chat, email, and other methods.
              </p>
              <br />
              <p className='box2-para2' style={{ fontWeight: '400', fontSize: '20px', fontFamily: 'Space Grotesk', color: '#DADADA' }}>
                At Telemo we understand that every interaction is an opportunity to build trust and loyalty. That's why we go above and beyond to integrate cutting-edge technology with human-centric approaches, ensuring that each customer interaction is personalized, efficient, and memorable. With our comprehensive suite of solutions, we empower businesses to exceed customer expectations, drive growth, and establish themselves as industry leaders in providing the Best Contact Center Solution.
              </p>

            </div>
          </div>
        </div>

     <TrustedUsers />


        <div className='cc-forth'>
          <h1 className='cc-forth-h1' > <span >Benefits of our Extensive</span> <br />  Contact Center Solution</h1>

        </div>


        <div className='cc-fifth-section'>

          {/* Box 1 */}
          <div className='fifth-box1' >
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />
            <h1 className='fifth-box1-h1'>Enhanced customer service management</h1>
            <p  className='fifth-box1-para'>Manage customer interactions seamlessly across multiple channels, including phone, email, chat, and social media. This centralized hub provides agents with a holistic view of each customer, enabling them to resolve inquiries efficiently and deliver personalized service that exceeds expectations.</p>
          </div>

          {/* Box 2 */}
          <div className='fifth-box2' >
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />

            <h1 className='fifth-box2-h1' >Improved productivity</h1>
            <p className='fifth-box2-para'>Our contact center solution automates routine tasks like call routing, skills-based routing, and self-service options. This frees up valuable agent time for handling complex issues and building stronger customer relationships.</p>
          </div>

          {/* Box 3 */}
          <div className='fifth-box3'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />
            <h1 className='fifth-box3-h1'>Superior customer experience</h1>
            <p className='fifth-box3-para'>Our solution empowers you to deliver an omnichannel experience that caters to your customers' preferred communication methods. Additionally, features like real-time sentiment analysis and customer journey mapping enable you to identify areas for improvement and continuously refine your service offerings.</p>
          </div>

          {/* Box 4 */}
          <div className='fifth-box4'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />

            <h1 className='fifth-box4-h1'>Better security of data</h1>
            <p className='fifth-box4-para'>Our contact center solution prioritizes the protection of sensitive customer information by adhering to industry-leading security protocols. Encryption, access control, and comprehensive audit trails ensure your data remains safe and secure.</p>
          </div>
          {/* Box 5 */}
          <div className='fifth-box5'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />
            <h1 className='fifth-box5-h1'>Real-time monitoring and detailed reports</h1>
            <p className='fifth-box5-para' >Gain valuable insights into your contact center performance with real-time monitoring and detailed reports. Track key metrics like call volume, agent performance, and customer satisfaction. These insights empower you to identify trends, optimize workflows, and make data-driven decisions to continually enhance your customer service strategy. </p>
          </div>

          {/* Box 6 */}
          <div className='fifth-box6'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />

            <h1 className='fifth-box6-h1'>Dynamic scalability</h1>
            <p className='fifth-box6-para' >Our solution boasts dynamic scalability, allowing you to effortlessly add or remove features and agents based on your real-time requirements. This ensures you're always equipped to handle customer interactions effectively, regardless of seasonal fluctuations or unexpected surges in demand .</p>
          </div>

          <div className='fifth-sec-img'>
            <img
              alt="..."
              src={require("assets/img/Okay (2).png")}
              style={{
                width: '70%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </div>
        </div>


        <div className='cc-sixth-section'>

          <h1 className='sixth-sec-h1'> Enhance Business Proficiency  Using Our Contact Center Solution Services</h1>
          <p className='sixth-sec-p'>Telemo goes beyond simply providing software. We offer a complete suite of services to ensure your contact center runs smoothly and delivers exceptional customer experiences. Here's what we can do for you:</p>
          <div className='sixth-sec-main'>
            {/* Box 1 */}


            <div className='sixth-sec-box1' >
              <div>
                <div color="primary" onClick={() => toggle(1)} style={{ marginBottom: '1rem', width: '100%',cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: '24px', fontWeight: '400', color: '#FFFFFF' }}>
                    <span>Seamless Implementation & Integration</span>
                    <span>{openId === 1 ? '-' : '+'}</span>
                  </div>
                </div>
                <Collapse isOpen={openId === 1}>
                  <Card>
                    <CardBody style={{ textAlign: 'start' }}>
                      <ul className='list-style'>
                        <li>Our experienced engineers handle the entire implementation process, ensuring a smooth transition to your new contact center solution.</li>
                        <li>We seamlessly integrate Telemo with your existing CRM, ticketing systems, and other business applications Lorem, ipsum dolor sit amet consectetur adipisicing.</li>
                        <li>We provide comprehensive data migration services to ensure a clean and efficient transfer of your customer data.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  width: '100%',
                  border: '1px solid transparent',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 0.5
                }} />
              </div>

              <div>
                <div color="primary" onClick={() => toggle(2)} style={{ marginBottom: '1rem', width: '100%' ,cursor: 'pointer',}}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: '24px', fontWeight: '400', color: '#FFFFFF' }}>
                    <span>  Needs Assessment & Solution Design</span>
                    <span> {openId === 2 ? '-' : '+'}</span>
                  </div>

                </div>
                <Collapse isOpen={openId === 2}>
                  <Card>
                    <CardBody>

                    <ul className='list-style'>
                        <li>We work closely with you to understand your unique business needs, customer touchpoints, and current challenges.</li>
                        <li>We analyze your existing infrastructure and identify areas for improvement.</li>
                        <li>Our team of experts designs a customized contact center solution that aligns with your specific goals and budget.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />
              </div>

              <div>
                <div color="primary" onClick={() => toggle(3)} style={{ marginBottom: '1rem', width: '100%',cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: '24px', fontWeight: '400', color: '#FFFFFF' }}>
                    <span> Agent Training & Onboarding</span>
                    <span> {openId === 3 ? '-' : '+'}</span>
                  </div>

                </div>
                <Collapse isOpen={openId === 3}>
                  <Card>
                    <CardBody>
                    <ul className='list-style'>
                        <li>We offer comprehensive training programs to equip your agents with the skills and knowledge they need to excel using the Telemo platform.</li>
                        <li>Our training covers all aspects of the platform, from call routing and queue management to knowledge base utilization and customer interaction best practices.</li>
                        <li>We provide ongoing support and resources to ensure your agents remain confident and up-to-date.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />
              </div>

              <div>
                <div color="black" onClick={() => toggle(4)} style={{ marginBottom: '1rem', width: '100%',cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: '24px', fontWeight: '400', color: '#FFFFFF' }}>
                    <span > 24/7 Ongoing Support & Maintenance</span>
                    <span > {openId === 4 ? '-' : '+'}</span>
                  </div>
                </div>
                <Collapse isOpen={openId === 4}>
                  <Card>
                    <CardBody>
                    <ul className='list-style'>
                        <li>We offer dedicated 24/7 support to ensure your contact center runs smoothly around the clock.</li>
                        <li>Our team of experts is readily available to address any technical issues or answer your questions promptly.</li>
                        <li>We continuously monitor your system performance and proactively identify potential problems before they impact your operations.</li>
                      </ul>

                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />

              </div>
              <div>
                <div color="black" onClick={() => toggle(5)} style={{ marginBottom: '1rem', width: '100%' ,cursor: 'pointer',}}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: '24px', fontWeight: '400', color: '#FFFFFF' }}>
                    <span > Optimization & Reporting</span>
                    <span > {openId === 5 ? '-' : '+'}</span>
                  </div>
                </div>
                <Collapse isOpen={openId === 5}>
                  <Card>
                    <CardBody>
                    <ul className='list-style'>
                        <li>We provide ongoing performance analysis and reporting to help you track key metrics and identify opportunities for improvement.</li>
                        <li>Our team works with you to refine your call routing strategies, agent workflows, and self-service options based on data-driven insights.</li>
                        <li> We help you optimize your contact center operations for maximum efficiency and customer satisfaction.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div>

            {/* Box 2 */}
            <div className='sixth-sec-box2'>
              <img
                alt="..."
                src={require("assets/img/Landing Page 1.svg").default}
                className='sixth-sec-box2-img'
            
              />
            </div>
          </div>
        </div>

        <div className='CC-partner-section-img1'
       >
          <img
            style={{
              width: '43%',
              height: '50%',
            }}
            alt="..."
            src={require("assets/img/Vector.svg").default}
          />
        </div>
        <div className='CC-partner-section-img2'>
        <img
          style={{
            width: '10%',
            height: '50%',
            margin: '-36% 0% 0% 0%'

          }}
          alt="..."
          src={require("assets/img/Ellipse 7.svg").default}

        />
      </div>

      <div className='CC-partner-section-img3'>
        <img
          style={{
            width: '15%',
            height: '50%',
             margin: '-33% 0% 0% 0%'
          }}
          alt="..."
          src={require("assets/img/Ellipse 6.svg").default}
        />
      </div>

      <div className="CC-Partner-Section">
        <div className="CC-first-section">

          <h1>
            Become a Partner
          </h1>
          <div className="glassmorphism" style={{ width: '82%', padding: '20px' }} >
            <p style={{ textAlign: 'start', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '400' }}>
              We're actively pursuing partnerships on a global scale. Collaborating with us means forging a strategic alliance aimed at revolutionizing customer experiences and spearheading transformative initiatives for enterprises worldwide. As our esteemed partner, you'll unlock access to state-of-the-art AI technology and a dedicated team fully devoted to your triumph. Together, we'll co-create tailor-made solutions grounded in feedback, unleashing the full potential of your brand, elevating customer satisfaction to unprecedented levels, and driving substantial revenue growth.
            </p>
            <button className="button-bts bts" onClick={handleClick} >
              <img alt="Handshake icon" className="path" src={require("assets/img/Vector (5).svg").default} />
              <span style={{ marginLeft: '5px', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '500', color: 'rgba(255, 255, 255, 0.8)' }}>Become a Partner</span>
            </button>
          </div>
        </div>

        <div className="second-section">
          <img
            alt="..."
            src={require("assets/img/Frame 147.svg").default}
            style={{
              width: '100%',
              height: '60%',
              objectFit: 'cover',
              borderRadius: '20px'
            }}
          />
        </div>
      </div>
      <div className='Explore-main'>
        <div className='Explore-first'>
          <button className='Explore-btn'

          >
            OUR PRODUCTS
          </button>

          <h1 className='explore-h1'>Explore Our <span style={{ fontFamily: 'Playfair Display' }}>Other</span>  Product</h1>
        </div>


        {/* Box 1 */}
        <div className='explore-box1'>

          <img
            alt="..."
            src={require("assets/img/AI-agent1.svg").default}
            onClick={navigateAi}
            className='box1-img'
          />

        </div>

        <div className='explore-box2'>
          {/* Box 1 */}
          <div className='box2-img1'>

            <img
              className='img1-a'
              alt="Box 2 Image"
              src={require("assets/img/Sentiment.svg").default}
              onClick={sentimentAnalysis}
            />
          </div>

          {/* Box 2 */}
          <div className='box2-img2'>
            <img
              className='img2-a'
              alt="Box 1 Image"
              src={require("assets/img/Generative AI.svg").default}
              onClick={generativeAi}
            />
          </div>
        </div>

      </div>
        <Footer />
    </>
  );
}

export default ContactCenter;
