import React, { useState , useEffect} from 'react';
import { useNavigate} from 'react-router-dom';
import {
  Card,
  CardBody,
  Button,
  Collapse,
} from 'reactstrap';

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import TrustedUsers from 'views/IndexSections/TrustedUsers';
import "./Omnichannel.css";


const Omnichannel =() => {
  const [openId, setOpenId] = useState(null);

  const toggle = (id) => {
    if (openId === id) {
      setOpenId(null);
    } else {
      setOpenId(id);
    }
  };

  const navigate = useNavigate();

  const navigateAi = () => {
    navigate("/ai-agent-development-company")
    window.location.reload();
  }

  const sentimentAnalysis = () => {
    navigate("/sentiment-analysis-development-company")
    window.location.reload();
  }

  const generativeAi = () => {
    navigate("/generative-ai-development-company")
    window.location.reload();
  }

  function handleClick() {
    const purpose = "Become-Partner"
    localStorage.setItem("Purpose", purpose)
    navigate("/contact-us");
  }

  useEffect(() => {
    // Set the document title
    document.title = 'AI Omnichannel Solutions | Top Omnichannel Providers';
  }, []);

 
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className='Oc-main'>
            <h1 className='Oc-main-heading'>
              Omnichannel <br />
            </h1>
            <div className='Oc-main-middle'>
              <img
                style={{ width: '55%', }}
                alt="..."
                src={require("assets/img/Rectangle 13 (2).svg").default}
              /></div>
            <div className='Oc-main-border'>
              <p className='Oc-main-border-p' >Omnichannel solutions provide a unified experience across all your customer touchpoints, from online stores and social media to physical stores and call centers. This includes features like consistent product information, smooth handoffs between channels (like buying online and picking up in-store), and personalized marketing that follows customers wherever they interact with your brand.</p>
            </div>
            <img
            className='Oc-main-end'
              alt="..."
              src={require("assets/img/Rectangle 11 (2).svg").default}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'end', }}>
            {/* <img
              style={{
                width: '30%',
                height: '50%', 
                marginTop: '-50%',
                marginLeft: '0%',
                objectFit: 'cover',
                borderRadius: '20px'
              }}
              alt="..."
              src={require("assets/img/Vector (6).png")}
            /> */}

          </div>
        </div>


        <div className='Oc-second-sec' >
          <div className='Oc-second-main'>
            {/* Box 1 */}
            <div className='Oc-second-box1'>
              <h1 className='Oc-second-box1-h1'>What is Omnichannel <br />CX ?</h1>
              <img
                alt="..."
                src={require("assets/img/Rectangle 16 (2).svg").default}
                className='Oc-second-box1-img'
              />
            </div>

            {/* Box 2 */}
            <div className='Oc-second-box2'>
              <p className='Oc-second-box2-p'>
              Omnichannel CX is a multichannel customer experience (CX) strategy that provides a seamless and consistent experience for customers across all channels, including in-store, online, mobile, social media, and email. It is a customer-centric approach that focuses on the customer's journey and ensures that they have a positive experience no matter how they choose to interact with your brand. We ensure our clients get next-gen Omnichannel solutions that merge digital and physical aspects in a single platform.
              </p>
              <br />
              <p className='Oc-second-box2-p' >
              Deliver exceptional customer experiences with Telemo, a leading provider of Omnichannel CX solutions. We create seamless, consistent interactions across all channels – in-store, online, mobile, and more. Our comprehensive suite of services empowers you to map the customer journey, break down silos, and leverage technology for a unified CX strategy. Contact Telemo today and see how we can elevate your brand interactions.
              </p>

            </div>
          </div>
        </div>

     
      
      <TrustedUsers />


        <div className='Oc-third'>
        <h1 className='Oc-third-heading' > <span style={{marginLeft:'14%'}}>Key Advantages of</span>  <br /> Using Omnichannel Solutions </h1>

      </div>


      <div className='Oc-forth-main'>
        {/* Box 1 */}
        <div className='Oc-forth-box1'>
          <img
            alt="..."
            src={require("assets/img/Polygon 1.png")}
          />
          <h1 className='Oc-forth-box1-h1' >Increased customer satisfaction</h1>
          <p className='Oc-forth-box1-p' >By offering seamless transitions between channels (like voice, chat, or social media), Telemo ensures customers can effortlessly reach the support they need, on their preferred platform. This eliminates frustration and fosters a more positive experience.</p>
        </div>

        {/* Box 2 */}
        <div className='Oc-forth-box2'>
           <img
            alt="..."
            src={require("assets/img/Polygon 1.png")}
          />

          <h1 className='Oc-forth-box2-h1' >Enhanced customer loyalty</h1>
          <p className='Oc-forth-box2-p' >By providing a frictionless and efficient experience across all touchpoints, Telemo fosters customer loyalty. Imagine a scenario where a customer initiates a chat about an order, then effortlessly transitions to a voice call for a more complex inquiry. This continuity builds trust and encourages customers to choose your brand for future interactions.</p>
        </div>

        {/* Box 3 */}
        <div className='Oc-forth-box3'>
          <img
            alt="..."
            src={require("assets/img/Polygon 1.png")}
          />
          <h1 className='Oc-forth-box3-h1' >Improved customer engagement</h1>
          <p className='Oc-forth-box3-p' >Our Omnichannel Solutions goes beyond just providing service; it's about creating an ongoing conversation with customers. By meeting customers on the channels they use most, businesses can gather valuable insights into their needs and preferences.</p>
        </div>

        {/* Box 4 */}
        <div className='Oc-forth-box4'>
          <img
            alt="..."
            src={require("assets/img/Polygon 1.png")}
          />

          <h1 className='Oc-forth-box4-h1'>Deeper customer insights</h1>
          <p className='Oc-forth-box4-p'>An omnichannel customer experience (CX) strategy offers a wealth of data on how customers interact with your brand across various touchpoints. By analyzing this data, you can gain a holistic understanding of your customers, including their needs and preferences, pain points and frustrations, and their entire customer journey.</p>
        </div>
        <div className='Oc-forth-box5'>
          <img
            alt="..."
            src={require("assets/img/Polygon 1.png")}
          />
          <h1 className='Oc-forth-box5-h1'>Boosted sales and profitability</h1>
          <p className='Oc-forth-box5-p'>By analyzing customer behavior, you can remove purchase roadblocks and personalize the buying journey, leading to higher conversion rates. Satisfied customers with positive omnichannel experiences become loyal brand advocates, boosting customer lifetime value. In short, omnichannel CX is a powerful tool to turn customer data into increased sales and profitability.</p>
        </div>
        <div className='Oc-forth-box6'>
          <img
            alt="..."
            src={require("assets/img/Polygon 1.png")}
          />
          <h1 className='Oc-forth-box6-h1'>Competitive advantage</h1>
          <p className='Oc-forth-box6-p'>Omnichannel Solutions empowers you to create a seamless and convenient experience, personalize the customer journey based on individual needs, and provide proactive customer service. Ultimately, this translates to happier customers, increased brand loyalty, and a competitive edge through superior CX.</p>
        </div>
        

      </div>
      <div className='Oc-fifth'>
              <img
                alt="..."
                src={require("assets/img/Okay (2).png")}
                className='Oc-fifth-img'
              />
            </div>

        <div className='Oc-sixth'>
            <h1 className='Oc-sixth-h1'> <span style={{marginLeft:'5%'}}>Our Extensive and </span>  <br /> Complete Omnichannel CX Solution </h1>
            <div className='Oc-sixth-main'>
              {/* Box 1 */}
            

            <div className='Oc-sixth-main-box1'>
              <div>
                <div color="primary" onClick={() => toggle(1)} style={{ marginBottom: '1rem', width: '100%' ,cursor: 'pointer',}}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%',fontSize:'24px', fontWeight:'400' ,color:'#FFFFFF'  }}>
                    <span> Omnichannel Strategy Consulting</span>
                    <span>{openId === 1 ? '-' : '+'}</span>
                  </div>
                </div>
                <Collapse isOpen={openId === 1}>
                  <Card>
                    <CardBody>
                    <ul className='bullet-remove'>
                    <li>We can help your company develop a winning omnichannel strategy. This means we'll work with you to create a seamless customer experience across all your touchpoints, whether it's online, in-store, or through mobile apps.</li>
                    </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />
              </div>

              <div>
                <div color="primary" onClick={() => toggle(2)} style={{ marginBottom: '1rem', width: '100%' ,cursor: 'pointer',}}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%',fontSize:'24px', fontWeight:'400' ,color:'#FFFFFF'  }}>
                    <span>  Omnichannel Implementation</span>
                    <span> {openId === 2 ? '-' : '+'}</span>
                  </div>

                </div>
                <Collapse isOpen={openId === 2}>
                  <Card>
                    <CardBody>
                    <ul className='bullet-remove'>
                    <li>We can implement omnichannel solutions for your company. We'll take your omnichannel strategy and turn it into reality, integrating your various channels to create a unified experience.</li>
                    </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />
              </div>

              <div>
                <div color="primary" onClick={() => toggle(3)} style={{ marginBottom: '1rem', width: '100%' ,cursor: 'pointer',}}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' ,fontSize:'24px', fontWeight:'400' ,color:'#FFFFFF' }}>
                    <span> Omnichannel Measurement and Analytics</span>
                    <span> {openId === 3 ? '-' : '+'}</span>
                  </div>

                </div>
                <Collapse isOpen={openId === 3}>
                  <Card>
                    <CardBody>
                    <ul className='bullet-remove'>
                    <li>We can help you measure and analyze the impact of your company's omnichannel efforts. This includes tracking key metrics to see how well your omnichannel strategy is performing and how it's impacting your customers.</li>
                    </ul>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>

             
            </div>

              {/* Box 2 */}
              <div className='Oc-sixth-main-box2'>
                <img
                  alt="..."
                  src={require("assets/img/Landing Page 1.svg").default}
                  style={{
                    width: '95%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </div>
            </div>
          </div>

          <div className='CC-partner-section-img1'
       >
          <img
            style={{
              width: '43%',
              height: '50%',
            }}
            alt="..."
            src={require("assets/img/Vector.svg").default}
          />
        </div>
        <div className='CC-partner-section-img2'>
        <img
          style={{
            width: '10%',
            height: '50%',
            margin: '-36% 0% 0% 0%'

          }}
          alt="..."
          src={require("assets/img/Ellipse 7.svg").default}

        />
      </div>

      <div className='CC-partner-section-img3'>
        <img
          style={{
            width: '15%',
            height: '50%',
             margin: '-33% 0% 0% 0%'
          }}
          alt="..."
          src={require("assets/img/Ellipse 6.svg").default}
        />
      </div>


      <div className="CC-Partner-Section">
        <div className="CC-first-section">

          <h1>
            Become a Partner
          </h1>
          <div className="glassmorphism" style={{ width: '82%', padding: '20px' }} >
            <p style={{ textAlign: 'start', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '400' }}>
              We're actively pursuing partnerships on a global scale. Collaborating with us means forging a strategic alliance aimed at revolutionizing customer experiences and spearheading transformative initiatives for enterprises worldwide. As our esteemed partner, you'll unlock access to state-of-the-art AI technology and a dedicated team fully devoted to your triumph. Together, we'll co-create tailor-made solutions grounded in feedback, unleashing the full potential of your brand, elevating customer satisfaction to unprecedented levels, and driving substantial revenue growth.
            </p>
            <button className="button-bts bts" onClick={handleClick} >
              <img alt="Handshake icon" className="path" src={require("assets/img/Vector (5).svg").default} />
              <span style={{ marginLeft: '5px', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '500', color: 'rgba(255, 255, 255, 0.8)' }}>Become a Partner</span>
            </button>
          </div>
        </div>

        <div className="second-section">
          <img
            alt="..."
            src={require("assets/img/Frame 147.svg").default}
            style={{
              width: '100%',
              height: '60%',
              objectFit: 'cover',
              borderRadius: '20px'
            }}
          />
        </div>
      </div>
      <div className='Explore-main'>
        <div className='Explore-first'>
          <button className='Explore-btn'

          >
            OUR PRODUCTS
          </button>

          <h1 className='explore-h1'>Explore Our <span style={{ fontFamily: 'Playfair Display' }}>Other</span>  Product</h1>
        </div>


        {/* Box 1 */}
        <div className='explore-box1'>

          <img
            alt="..."
            src={require("assets/img/AI-agent1.svg").default}
            onClick={navigateAi}
            className='box1-img'
          />

        </div>

        <div className='explore-box2'>
          {/* Box 1 */}
          <div className='box2-img1'>

            <img
              className='img1-a'
              alt="Box 2 Image"
              src={require("assets/img/Sentiment.svg").default}
              onClick={sentimentAnalysis}
            />
          </div>

          {/* Box 2 */}
          <div className='box2-img2'>
            <img
              className='img2-a'
              alt="Box 1 Image"
              src={require("assets/img/Generative AI.svg").default}
              onClick={generativeAi}
            />
          </div>
        </div>

      </div>
        <Footer />
      </div>
    </>
  );
}

export default Omnichannel
