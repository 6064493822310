import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
} from "reactstrap";
import './ContactCenter.css'
import './Solution.css'

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";



const Solution = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const images = [
    {
      src: require("assets/img/Frame 129.svg").default,
      title: 'Healthcare',
      description: "In the Healthcare sector, Telemo's AI solutions are not just tools, they are transformative. Our voice bots and AI agents revolutionize patient triage, appointment scheduling, and medical information provision. By reducing the burden on healthcare professionals, we empower them to focus more on patient care. Our AI solutions ensure accurate, prompt, and personalized healthcare services, inspiring a new era of healthcare delivery.",
    },
    {
      src: require("assets/img/Frame 129 (1).svg").default,
      title: 'Entertainment',
      description: "Telemo enhances user engagement in the Entertainment industry through personalized content delivery and interactive experiences. With AI-powered content recommendation engines and virtual assistants, our smart inventions analyze user preferences, predict content trends, and enhance content discovery across platforms. By offering tailored recommendations and real-time updates, we help entertainment providers increase viewer retention, improve user satisfaction, and maximize content monetization opportunities.",
    },
    {
      src: require("assets/img/Frame 129 (2).svg").default,
      title: 'Travel',
      description: "At Telemo, we understand that travel is about more than just reaching a destination. That's why our AI solutions for the travel industry, including chatbots for booking assistance and itinerary management, are designed with the customer in mind. These tools provide personalized travel recommendations, real-time updates, and instant customer support, ensuring a hassle-free travel experience and increased customer satisfaction.",
    },
    {
      src: require("assets/img/Frame 129.svg").default,
      title: 'Oil and Gas',
      description: "In the oil and gas industry, safety is paramount. That's why Telemo's AI-driven data and smart automation are not just about efficiency; they're about ensuring the safety of your operations. Our AI agents watch machinery, predict maintenance needs, and optimize resource management, ensuring reliable and cost-effective operations that you can trust.",
    },
    {
      src: require("assets/img/Frame 129 (1).svg").default,
      title: 'Telecom',
      description: "Telemo transforms the telecom industry with AI-powered chatbots and intelligent network management solutions. These technologies enhance customer support, automate routine tasks, and optimize network performance, ensuring reliable service delivery and improved customer satisfaction.",
    },
    {
      src: require("assets/img/Frame 129 (2).svg").default,
      title: 'Construction',
      description: "Telemo uses chatbots and AI-driven project management solutions for real-time updates and assistance in the construction industry. These solutions guarantee on-time completion and better project results by improving project planning, keeping an eye on developments, and answering questions quickly.",
    },
    {
      src: require("assets/img/Frame 129.svg").default,
      title: 'E-Commerce',
      description: "Telemo revolutionizes E-Commerce with AI-powered chatbots and personalized shopping assistants. These technologies enhance customer interactions, provide customized product recommendations, and streamline order management, ensuring a seamless shopping experience and increased customer satisfaction.",
    },
    {
      src: require("assets/img/Frame 129 (1).svg").default,
      title: 'Games',
      description: "Telemo increases player involvement in the game industry via chatbots for assistance and AI-driven game recommendations. Through personalized gaming experiences, prompt assistance, and real-time improvements, these solutions ensure a completely involved and enjoyable gaming experience.",
    },
    {
      src: require("assets/img/Frame 129 (2).svg").default,
      title: 'Finance',
      description: "We transform the finance sector with AI agents and intelligent automation for financial management and customer support. These technologies offer personalized financial advice, automate routine tasks, and provide real-time assistance, ensuring efficient and accurate financial services.",
    },
    {
      src: require("assets/img/Frame 129.svg").default,
      title: 'Politics',
      description: "Telemo uses AI-powered data analytics and chatbots for voter engagement and campaign management in politics. These solutions analyze voter sentiment, provide real-time updates, and handle queries, ensuring effective communication and strategic decision-making.",
    },
    {
      src: require("assets/img/Frame 129 (1).svg").default,
      title: 'Banking',
      description: "Telemo uses smart chatbots and cutting-edge fraud detection systems to improve bank customer service. These AI-powered tools allow customers to easily handle everyday transactions and help the bank automatically catch suspicious activity, making banking faster and safer.",
    },
    {
      src: require("assets/img/Frame 129 (2).svg").default,
      title: 'Real Estate',
      description: "We use artificial intelligence (AI) to help people find properties in the real estate market. Their AI tools recommend properties that might be a good fit for you, and can even schedule appointments to see them for you. We also make virtual assistants that can answer your questions about properties, making your search for a new place to live smoother and faster.",
    },
    {
      src: require("assets/img/Frame 129.svg").default,
      title: 'Education',
      description: "Our AI solutions transform education with AI-driven personalized learning and chatbots for student support. These technologies offer tailored educational content, manage administrative tasks, and provide instant assistance, ensuring an engaging and effective learning experience.",
    },
    {
      src: require("assets/img/Frame 129 (1).svg").default,
      title: 'News & Media',
      description: "Telemo enhances content delivery in the News industry with AI-driven news aggregation and personalized content recommendations. It analyzes reader preferences, predicts trending topics, and optimizes content distribution through chatbot interactions. This improves reader engagement, subscription rates, and advertising revenue for news publishers.",
    },
    {
      src: require("assets/img/Frame 129 (2).svg").default,
      title: 'Logistics',
      description: "We create smart chatbots and voicebots to optimize your deliveries. Our smart AI tools analyze data to find the best routes, predict inventory needs, and offer real-time support through these AI helpers. This translates to lower costs, fewer delivery mistakes, and a more efficient supply chain for your business.",
    },
    {
      src: require("assets/img/Frame 129.svg").default,
      title: 'Aviation',
      description: "In aviation, Telemo enhances safety with AI-driven predictive maintenance and optimization of flight operations. It monitors aircraft performance, predicts maintenance needs, and optimizes flight schedules based on weather and air traffic conditions. This ensures operational reliability, reduces maintenance costs, and enhances passenger safety and satisfaction with aviation services.",
    },
    {
      src: require("assets/img/Frame 129 (1).svg").default,
      title: 'Agriculture',
      description: "We transform Agriculture with AI-powered crop monitoring and predictive farming analytics. It analyzes soil conditions, predicts crop yields, and automates irrigation and pest control systems based on real-time data. This improves agricultural productivity, reduces resource waste, and promotes sustainable farming practices for agricultural enterprises.",
    },
    {
      src: require("assets/img/Frame 129 (2).svg").default,
      title: 'Automotive',
      description: "Telemo revolutionizes the Automotive industry with AI-driven autonomous driving technology and vehicle diagnostics. It enhances driver safety, predicts maintenance issues before they occur, and provides real-time navigation support through AI agents. Telemo's extensive solutions accelerate the evolution towards smart vehicles, improve road safety, and ensure reliable transportation solutions.",
    },
    {
      src: require("assets/img/Frame 129.svg").default,
      title: 'Manufacturing',
      description: "Our advanced solutions improve operational efficiency with AI-powered predictive maintenance and smart factory solutions. It predicts equipment failures, optimizes production schedules, and automates quality control processes based on real-time data analysis. Our AI solutions reduce downtime, enhance product quality, and ensure cost-effective manufacturing operations.",
    },
    {
      src: require("assets/img/Frame 129 (1).svg").default,
      title: 'On Demand ',
      description: "Our pioneers On Demand development with AI-driven solutions. By integrating advanced Chatbots, Voice bots, and cutting-edge technologies like CCaaS, UCaaS, Generative AI, and Omnichannel Solutions, we can optimize real-time service management, enhance delivery efficiency, and personalize user experiences. This ensures On Demand businesses meet dynamic customer needs promptly, boosting satisfaction, operational agility, and profitability.",
    },

  ];

  const totalImages = images.concat(images);
  const isTransitioning = useRef(false);

  useEffect(() => {
    let interval;
    if (isHovering) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => prevIndex + 3);
        isTransitioning.current = true;
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [isHovering]);

  useEffect(() => {
    if (currentIndex === images.length) {
      setTimeout(() => {
        setCurrentIndex(0);
        isTransitioning.current = false;
      }, 3000);
    }
  }, [currentIndex, images.length]);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };


  const navigate = useNavigate();

  const navigateAi = () => {
    navigate("/ai-agent-development-company")
    window.location.reload();
  }

  const sentimentAnalysis = () => {
    navigate("/sentiment-analysis-development-company")
    window.location.reload();
  }

  const generativeAi = () => {
    navigate("/generative-ai-development-company")
    window.location.reload();
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  function handleClick() {
    const purpose = "Become-Partner"
    localStorage.setItem("Purpose", purpose)
    navigate("/contact-us");
  }

  useEffect(() => {
    // Set the document title
    document.title = 'Custom AI Development Company | Tailored AI Solutions & Services';
  
  }, []);






  return (
    <>
      <ExamplesNavbar />
      <div className="page-header">
        <div className='main-header'>
          <div className='page-solution'>
            A solution  <br />
            <span>for everyone</span>
            <br /> <span>& everything</span>
          </div>
          <div
            className='img-solution'>
            <img
              style={{ width: '20%', marginTop: '-5%' }}
              alt="..."
              src={require("assets/img/sol2.svg").default}
              className='img-sol-1'

            />
            <img
              style={{ width: '52%', marginLeft: '7%', height: '50%' }}
              alt="..."
              src={require("assets/img/Rectangle 17.svg").default}
              className='img-sol-2'
            />
            <img
              style={{ width: '20%', marginTop: '14%', marginLeft: '-10%' }}
              alt="..."
              src={require("assets/img/sol1.svg").default}
              className='img-sol-3'
            />
          </div>


        </div>
      </div>
      <div className='sol-contant'>
        <div className='sol-img-sec'>
          <div className='sol-box1'>
            <h1 className='sol-box-1-text'>How to customise <br />solution for <span style={{ fontFamily: 'Playfair Display' }}>your</span>   <br />likings? </h1>
            <img
              alt="..."
              src={require("assets/img/Rectangle 16 (1).svg").default}
              className='sol-box-1-img'
            />
          </div>

          {/* Box 2 */}
          <div className='sol-box2'>
            <p className='sol-box2-text' >
              Telemo isn't just another AI development company; it's a revolution in crafting exceptional customer experiences. Leveraging the power of Generative AI, we build intelligent chatbots and virtual assistants that feel refreshingly human. Imagine a world where customer inquiries are met with natural conversation, not robotic pre-programmed responses. Our AI goes beyond basic question-answering, utilizing sentiment analysis and real-time data to personalize interactions and proactively address customer needs.

            </p>
            <br />
            <p className='sol-box2-text' >
              This translates to faster resolutions, reduced wait times, and a significant boost in customer satisfaction. Furthermore, our platform integrates seamlessly with existing communication channels, offering a truly omnichannel experience for your customers. Whether it's SMS, social media, email, or voice calls, we ensure a consistent and positive brand experience across all touchpoints.
            </p>


          </div>
        </div>
      </div>

      <div className='sol-part-3'>
        {/* Box 1 */}
        <div className='part-box1'
        >
          <button class="glassmorphism-button" style={{ fontSize: '12px' }}>OUR REACH</button>
          <h1 className='box1-head'>We cater all types of Business</h1>
        </div>

        {/* Box 2 */}
        <div className='part-box2'>
          <img
            alt="..."
            src={require("assets/img/Star 2 (1).png")}
          />
          <h1 className='box2-head' >Medium-sized Business</h1>
          <p className='box2-text'>Mid-sized companies benefit from our industry-specific software and data management systems. These solutions enable streamlined operations and enhanced customer engagement, helping you manage your resources effectively and improve customer satisfaction. Our AI technology is designed to adapt to your specific needs, providing you with the tools necessary to drive growth and innovation.</p>


        </div>

        {/* Box 3 */}
        <div className='part-box3'>
          <img
            alt="..."
            src={require("assets/img/Star 2 (1).png")}
          />
          <h1 className='box3-head'>For Startups</h1>
          <p className='box3-text'>We offer agile and scalable solutions such as Cloud Telephony, Cloud PBX, and AI Agents. These innovative solutions help you manage costs effectively and accelerate your entry into the market. Our omnichannel services ensure seamless customer engagement across various platforms, helping you build strong relationships with your customers from the start.</p>
        </div>

        {/* Box 4 */}
        <div className='part-box4'>
          <img
            alt="..."
            src={require("assets/img/Star 2 (1).png")}
          />

          <h1 className='box4-head' >Large-sized Business</h1>
          <p className='box4-text' >Large enterprises require comprehensive, fully integrated AI solutions, and Telemo delivers just that. We deliver comprehensive, fully integrated AI solutions to empower large organizations. Their advanced AI-driven analytics provide deep insights to inform strategic decision-making. We understand the complexities of large-scale operations and provide tailored solutions that enhance efficiency, protect your data, and optimize your supply chain. </p>
        </div>
      </div>

      <div
        className='main-img'
        style={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '10px',
          marginTop: '-30%'
        }}>
        <img
          alt="..."
          src={require("assets/img/Solutionokay.png")}
          style={{
            width: '70%',
            height: '100%',
            objectFit: 'cover',
          }}
          className='main-img1'
        />
      </div>

      <div className='sol-slider'>
        <button class="glassmorphism-button" style={{ fontSize: '12px', fontWeight: '400' }}>WE ARE EVERYWHERE</button>
        <h1 style={{ marginBottom: '20px', fontSize: '56px', fontWeight: '400', }}>Service in all industries</h1>
        <div className='slider-box'>
          <div
            className="slider-scroll"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className='div-slide'
              style={{
                display: 'flex',
                transition: isTransitioning.current ? 'transform 1s ease-in-out' : 'none',
                transform: `translateX(-${(currentIndex % images.length) * 9.2}%)`,
                width: `${totalImages.length * 9.2}%`,
              }}
            >
              {totalImages.map((image, index) => (
                <div
                  key={index}
                  className="image"
                  style={{
                    minWidth: '9.2%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    alt="..."
                    src={image.src}
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                  <div style={{ padding: '2%', textAlign: 'center' }}>
                    <div style={{ fontSize: '32px', fontWeight: '500', color: '#FFFFFF' }}>{image.title}</div>
                    <div style={{ fontSize: '18px', fontWeight: '400', color: '#DADADA', textAlign: 'start' }}>{image.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>


      <div
        style={{
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'start',
          width: '50%',
          marginTop: '5%',
          // border:'2px solid red'
        }}>
        <img
          style={{
            width: '43%',
            height: '50%',
          }}
          alt="..."
          src={require("assets/img/Vector.svg").default}
        />
      </div>



      <div className='sol-part'>
        <div className='part-text'>
          <div className='part-text-title'>
            Become a Partner
          </div>
          <div className="glassmorphism">
            <p>
              We're actively pursuing partnerships on a global scale. Collaborating with us means forging a strategic alliance aimed at revolutionizing customer experiences and spearheading transformative initiatives for enterprises worldwide. As our esteemed partner, you'll unlock access to state-of-the-art AI technology and a dedicated team fully devoted to your triumph. Together, we'll co-create tailor-made solutions grounded in feedback, unleashing the full potential of your brand, elevating customer satisfaction to unprecedented levels, and driving substantial revenue growth.
            </p>
            <button className="button-bts bts" onClick={handleClick}>
              <img alt="Handshake icon" className="path" src={require("assets/img/Vector (5).svg").default} />
              <span>Become a Partner</span>
            </button>
          </div>
        </div>
        <div className='part-image'>
          <img
            alt="..."
            src={require("assets/img/Frame 147.svg").default}
          />
        </div>
      </div>




      <div className='Explore-main'>
        <div className='Explore-first'>
          <button className='Explore-btn'

          >
            OUR PRODUCTS
          </button>

          <h1 className='explore-h1'>Explore Our <span style={{ fontFamily: 'Playfair Display' }}>Other</span>  Product</h1>
        </div>


        {/* Box 1 */}
        <div className='explore-box1'>

          <img
            alt="..."
            src={require("assets/img/AI-agent1.svg").default}
            onClick={navigateAi}
            className='box1-img'
          />

        </div>

        <div className='explore-box2'>
          {/* Box 1 */}
          <div className='box2-img1'>

            <img
              className='img1-a'
              alt="Box 2 Image"
              src={require("assets/img/Sentiment.svg").default}
              onClick={sentimentAnalysis}
            />
          </div>

          {/* Box 2 */}
          <div className='box2-img2'>
            <img
              className='img2-a'
              alt="Box 1 Image"
              src={require("assets/img/Generative AI.svg").default}
              onClick={generativeAi}
            />
          </div>
        </div>

      </div>

      <Footer />
    </>
  );
}

export default Solution
