import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  Collapse,
} from 'reactstrap';

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import TrustedUsers from 'views/IndexSections/TrustedUsers';
import "./Sentiment.css";


const SentimentAnalysis =() => {
  const [openId, setOpenId] = useState(null);

  const toggle = (id) => {
    if (openId === id) {
      setOpenId(null);
    } else {
      setOpenId(id);
    }
  };
  const navigate = useNavigate();

  const navigateAi = () => {
    navigate("/ai-agent-development-company")
    window.location.reload();
  }

  const sentimentAnalysis = () => {
    navigate("/sentiment-analysis-development-company")
    window.location.reload();
  }

  const generativeAi = () => {
    navigate("/generative-ai-development-company")
    window.location.reload();
  }

  function handleClick() {
    const purpose = "Become-Partner"
    localStorage.setItem("Purpose", purpose)
    navigate("/contact-us");
  }

  useEffect(() => {
    // Set the document title
    document.title = 'Leading Sentiment Analysis Development Company | Telemo';

  }, []);





  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className='Sentiment-main'>
            <h1 className='Sentiment-main-h1' >
              Sentiment <br />
              <span>Analysis</span>
            </h1>
            <div className='Sentiment-main-mid'>
              <img
              className='Sentiment-main-mid-img'
                alt="..."
                src={require("assets/img/Rectangle 13 (1).svg").default}
              /></div>
            <div className='Sentiment-main-border'>
              <p className='Sentiment-main-border-p'>With our Sentiment Analysis feature, we can help you track online conversations about your brand, analyze customer feedback from various sources, conduct market research to understand your target audience, and perform competitive analysis to gain insights into your competitors' offerings and customer sentiment. This all translates to valuable business insights that you can use to improve brand reputation, enhance customer experience, and ultimately boost sales.</p>
            </div>
            <img
              className='Sentiment-main-img'
              alt="..."
              src={require("assets/img/Rectangle 11 (1).svg").default}
            />
          </div>

        </div>

        <div className='Sentiment-second'>
          <div className='Sentiment-second-main'>
            {/* Box 1 */}
            <div className='Sentiment-second-box1'>
              <h1 className='Sentiment-second-box1-h1'>What is Sentiment<br />Analysis?</h1>
              <img
                alt="..."
                src={require("assets/img/Rectangle 16 (1).svg").default}
                className='Sentiment-second-box1-img'
              />
            </div>

            {/* Box 2 */}
            <div className='Sentiment-second-box2'>
              <p className='Sentiment-second-box2-para' >
                Imagine being able to understand the emotions behind people's words. Sentiment analysis is like having that superpower for your business! It uses natural language processing (NLP) and machine learning to gauge the emotional tone of text data. This can be reviews, social media posts, emails, or even chat conversations. Sentiment analysis can tell you if the sentiment is positive, negative, or neutral. By understanding how people feel about your brand, products, or services, you can gain valuable insights to improve customer satisfaction, target marketing campaigns more effectively, and make data-driven business decisions.
              </p>
              <br />
              <p className='Sentiment-second-box2-para' >
                We leverage this advanced technology to extract valuable insights from customer conversations, including emails, chats, social media posts, and even survey responses. Sentiment Analysis can determine whether a customer's sentiment is positive, negative, or neutral. With our sentiment analysis tools, you can gain a deeper understanding of customer satisfaction, identify areas for improvement, and personalize your communication strategies to build stronger customer relationships.
              </p>

            </div>
          </div>
        </div>


     <TrustedUsers />


        <div className='Sentiment-third'>
          <h1 className='Sentiment-third-h1'>  Benefits of Our <br /> Sentiment Analysis Solutions </h1>

        </div>


        <div className='Sentiment-forth'>
          {/* Box 1 */}
          <div className='Sentiment-forth-box1'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />
            <h1 className='Sentiment-forth-box1-h1'>Improve customer satisfaction</h1>
            <p className='Sentiment-forth-box1-para' >Our sentiment analysis solution empowers you to deliver exceptional service. It helps you proactively identify dissatisfied customers through their reviews, social media posts, and support tickets, enabling you to address their concerns promptly and prevent churn. Furthermore, it analyzes overall customer sentiment across various touchpoints to measure satisfaction levels and pinpoint areas for improvement.</p>
          </div>

          {/* Box 2 */}
          <div className='Sentiment-forth-box2' >
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />

            <h1 className='Sentiment-forth-box2-h1' >Enhance product development</h1>
            <p className='Sentiment-forth-box2-para'>You can uncover customer needs by understanding their preferences, pain points, and feature requests. This data directly informs product development, ensuring your offerings resonate with your target audience. Additionally, you can track the performance of new features, monitoring sentiment to assess their impact and identify areas for improvement.</p>
          </div>

          {/* Box 3 */}
          <div className='Sentiment-forth-box3'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />
            <h1 className='Sentiment-forth-box3-h1' >Monitor brand reputation</h1>
            <p className='Sentiment-forth-box3-para' >By actively monitoring online conversations, you can stay on top of your brand image and protect your reputation. This includes identifying mentions of your brand across various platforms to understand customer perception, addressing negative sentiment quickly to nip potential PR crises in the bud, and measuring brand advocacy to gauge customer loyalty and identify brand champions.</p>
          </div>

          {/* Box 4 */}
          <div className='Sentiment-forth-box4'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />

            <h1 className='Sentiment-forth-box4-h1'>Gain competitive intelligence</h1>
            <p className='Sentiment-forth-box4-para'>This analysis can be extended to identify broader industry trends and customer preferences. By comparing your brand sentiment with your competitors, you can benchmark your performance and pinpoint areas for improvement or highlight where you already shine.</p>
          </div>
        </div>

        <div className='Sentiment-fifth'>
          <img
            alt="..."
            src={require("assets/img/Okay (2).png")}
            className='Sentiment-fifth-img'
          />
        </div>
        <div className='Sentiment-sixth'>
          <h1 className='Sentiment-sixth-h1'> Our Services in  <br /> Sentiment Analysis Solution</h1>
          <div className='Sentiment-sixth-main'>
            {/* Box 1 */}


            <div className='Sentiment-sixth-box1'>
              <div>
                <div color="primary" onClick={() => toggle(1)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400',cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                    <span>Pre-trained Sentiment Analysis models</span>
                    <span>{openId === 1 ? '-' : '+'}</span>
                  </div>
                </div>
                <Collapse isOpen={openId === 1}>
                  <Card>
                    <CardBody>
                      <ul className='bullet-remove'>
                        <li>We offer a variety of pre-trained sentiment analysis models that can be used to classify text as positive, negative, or neutral.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />
              </div>

              <div>
                <div color="primary" onClick={() => toggle(2)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400',cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                    <span>Custom Sentiment Analysis models</span>
                    <span> {openId === 2 ? '-' : '+'}</span>
                  </div>

                </div>
                <Collapse isOpen={openId === 2}>
                  <Card>
                    <CardBody>
                      <ul className='bullet-remove'>
                        <li>We can train custom sentiment analysis models for your specific needs and requirements.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />
              </div>

              <div>
                <div color="primary" onClick={() => toggle(3)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400',cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                    <span>Sentiment Analysis APIs</span>
                    <span> {openId === 3 ? '-' : '+'}</span>
                  </div>

                </div>
                <Collapse isOpen={openId === 3}>
                  <Card>
                    <CardBody>
                    <ul className='bullet-remove'>
                      <li>We offer a variety of sentiment analysis APIs that can be easily integrated with your existing systems.</li>
                    </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />
              </div>
              <div>
                <div color="primary" onClick={() => toggle(4)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400',cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                    <span> Sentiment Analysis consulting</span>
                    <span> {openId === 4 ? '-' : '+'}</span>
                  </div>

                </div>
                <Collapse isOpen={openId === 4}>
                  <Card>
                    <CardBody>
                    <ul className='bullet-remove'>
                      <li>We offer consulting services to help you implement and use sentiment analysis effectively.</li>
                    </ul>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>

            </div>

            {/* Box 2 */}
            <div className='Sentiment-sixth-box2'>
              <img
                alt="..."
                src={require("assets/img/Landing Page 1 (2).png")}
                style={{
                  width: '95%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </div>
          </div>
        </div>

        <div className='CC-partner-section-img1'
       >
          <img
            style={{
              width: '43%',
              height: '50%',
            }}
            alt="..."
            src={require("assets/img/Vector.svg").default}
          />
        </div>
        <div className='CC-partner-section-img2'>
        <img
          style={{
            width: '10%',
            height: '50%',
            margin: '-36% 0% 0% 0%'

          }}
          alt="..."
          src={require("assets/img/Ellipse 7.svg").default}

        />
      </div>

      <div className='CC-partner-section-img3'>
        <img
          style={{
            width: '15%',
            height: '50%',
             margin: '-33% 0% 0% 0%'
          }}
          alt="..."
          src={require("assets/img/Ellipse 6.svg").default}
        />
      </div>


      <div className="CC-Partner-Section">
        <div className="CC-first-section">

          <h1>
            Become a Partner
          </h1>
          <div className="glassmorphism" style={{ width: '82%', padding: '20px' }} >
            <p style={{ textAlign: 'start', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '400' }}>
              We're actively pursuing partnerships on a global scale. Collaborating with us means forging a strategic alliance aimed at revolutionizing customer experiences and spearheading transformative initiatives for enterprises worldwide. As our esteemed partner, you'll unlock access to state-of-the-art AI technology and a dedicated team fully devoted to your triumph. Together, we'll co-create tailor-made solutions grounded in feedback, unleashing the full potential of your brand, elevating customer satisfaction to unprecedented levels, and driving substantial revenue growth.
            </p>
            <button className="button-bts bts" onClick={handleClick} >
              <img alt="Handshake icon" className="path" src={require("assets/img/Vector (5).svg").default} />
              <span style={{ marginLeft: '5px', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '500', color: 'rgba(255, 255, 255, 0.8)' }}>Become a Partner</span>
            </button>
          </div>
        </div>

        <div className="second-section">
          <img
            alt="..."
            src={require("assets/img/Frame 147.svg").default}
            style={{
              width: '100%',
              height: '60%',
              objectFit: 'cover',
              borderRadius: '20px'
            }}
          />
        </div>
      </div>
      <div className='Explore-main'>
        <div className='Explore-first'>
          <button className='Explore-btn'

          >
            OUR PRODUCTS
          </button>

          <h1 className='explore-h1'>Explore Our <span style={{ fontFamily: 'Playfair Display' }}>Other</span>  Product</h1>
        </div>


        {/* Box 1 */}
        <div className='explore-box1'>

          <img
            alt="..."
            src={require("assets/img/AI-agent1.svg").default}
            onClick={navigateAi}
            className='box1-img'
          />

        </div>

        <div className='explore-box2'>
          {/* Box 1 */}
          <div className='box2-img1'>

            <img
              className='img1-a'
              alt="Box 2 Image"
              src={require("assets/img/Sentiment.svg").default}
              onClick={sentimentAnalysis}
            />
          </div>

          {/* Box 2 */}
          <div className='box2-img2'>
            <img
              className='img2-a'
              alt="Box 1 Image"
              src={require("assets/img/Generative AI.svg").default}
              onClick={generativeAi}
            />
          </div>
        </div>

      </div>


        <Footer />
      </div>
    </>
  );
}

export default SentimentAnalysis
