import React, { useState, useEffect } from "react";
import './FirstProduct.css';
import './Trusted.css';
import { Link } from 'react-router-dom';

const TrustedUsers=()=> {


  return (
    <div className="trusted-user-main">
        <div className="user-section">
          <h1 className="user-h1" >Trusted users</h1>

          <div className="user-box1">
            {/* <img
              alt="..."
              src={require("assets/img/image 24.png")}
              style={{
                maxWidth: '80%',
                maxHeight: '80%',
                mixBlendMode: 'multiply',
                borderRadius: '50%'
              }}
            /> */}
          </div>

          <div className="user-box2">
            <img
              alt="..."

              src={require("assets/img/Group 772.png")}
              style={{
                maxWidth: '80%',
                maxHeight: '80%',

              }}
            />
          </div>

          <div className="user-box3">
            <img
              alt="..."
              src={require("assets/img/image 23.png")}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            />
          </div>

          <div className="user-box4">
            <img
              alt="..."
              src={require("assets/img/image 26.png")}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            />
          </div>



          <div className="user-box5">
            <img
              alt="..."
              src={require("assets/img/image 25.png")}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                // objectFit: 'contain'

              }}
            />
          </div>
        </div>
      </div>
  );
}

export default TrustedUsers;


