import React, { useState, useEffect } from "react";
import './FirstProduct.css';
import { Link } from 'react-router-dom';
import AIAgentImage from 'assets/img/AI-agent1.svg';
import SentimentImage from 'assets/img/Sentiment.svg';
import GenerativeAIImage from 'assets/img/Generative AI.svg';

const FirstProduct=()=> {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animate, setAnimate] = useState(false);

  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);

  const buttonStyle1 = {
    borderRadius: '50%',
    width: '62px',
    height: '62px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #542b9e',
    cursor: 'pointer',
    outline: 'none',
    backgroundColor: hover1 ? '#764ab0' : 'rgba(76, 25, 161, 1)',
    color: hover1 ? '#fff' : '#000',
  };

  const buttonStyle2 = {
    borderRadius: '50%',
    width: '62px',
    height: '62px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    cursor: 'pointer',
    outline: 'none',
    backgroundColor: hover2 ? 'black' : 'rgba(255, 255, 255, 0.04)',
    color: hover2 ? '#000' : '#fff',
  };

  const images = [AIAgentImage, SentimentImage, GenerativeAIImage];

  const getImageOrdinal = (index) => {
    const ordinals = ["AI Agent", "Sentiment Analysis", "Generative AI"];
    return ordinals[index] || index + 1;
  };

  const getLink = (ordinal) => {
    switch (ordinal) {
      case 'AI Agent':
        return '/ai-agent-development-company';
      case 'Sentiment Analysis':
        return '/sentiment-analysis-development-company';
      case 'Generative AI':
        return '/generative-ai-development-company';
      default:
        return '#';
    }
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const imageStyle = {
    maxWidth: "65%",
    maxHeight: "100%",
    borderRadius:'20px',
    transition: "all 1s ease-in-out",
  };

  const blurredImageStyle = {
    maxWidth: "55%",
    maxHeight: "100%",
    filter: "blur(1px)",
    position: "absolute",
    bottom: "-5%",
    left: "70%",
    transform: "translateX(-50%)",
    zIndex: -1,
    borderRadius:'20px',

  };
  useEffect(() => {
    setAnimate(true);
    const timeout = setTimeout(() => {
      setAnimate(false);
    }, 1000); // Match the duration of the CSS animation

    return () => clearTimeout(timeout);
  }, [currentIndex]);

  return (
    <div className="Agent-section">
      <div className="box1">
        <img
          alt="Displayed Product"
          src={images[currentIndex]}
          style={imageStyle}
          className={animate ? 'slide-animation' : ''}
        />
        {currentIndex === 0 && (
          <img
            alt="Blurred Image"
            src={images[1]}
            style={blurredImageStyle}
          />
        )}
        {currentIndex === 1 && (
          <img
            alt="Blurred Image"
            src={images[2]}
            style={blurredImageStyle}
          />
        )}
        {currentIndex === 2 && (
          <img
            alt="Blurred Image"
            src={images[0]}
            style={blurredImageStyle}
          />
        )}
      </div>
      <div
        className="box2"
        style={{
          // width: "33%",
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginLeft: '10%',
          marginTop: '6%',
        }}
      >
        <div className="box2-main">
          <p className="main-nub">
            0{currentIndex + 1}
          </p>
          <p className="main-text" >
            {getImageOrdinal(currentIndex)}
          </p>
          <p className="main-link">
            <Link
              to={getLink(getImageOrdinal(currentIndex))}
              style={{
                textDecoration: 'none',
                color: '#D4D4D4',
                fontSize: '21px',
                transition: 'color 0.3s',
                width: '100%',
              }}
              onMouseEnter={(e) => e.target.style.color = 'rgba(76, 25, 161, 1)'}
              onMouseLeave={(e) => e.target.style.color = '#D4D4D4'}
            >
              Explore more
            </Link>
          </p>
        </div>
        <div className="box3-main">
          <div style={{ display: 'flex', gap: '20px' }}>
            <button onClick={prevImage} style={buttonStyle2}
              onMouseEnter={() => setHover2(true)}
              onMouseLeave={() => setHover2(false)}>
              <img src={require("assets/img/ArrowDownLeft.png")} alt="Previous" />
            </button>
            <button onClick={nextImage} style={buttonStyle1}
              onMouseEnter={() => setHover1(true)}
              onMouseLeave={() => setHover1(false)}
            >
              <img src={require("assets/img/ArrowUpRight.png")} alt="Next" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstProduct;




