import React, { useState } from 'react';
import './footer.css'
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars, ColorRing, ThreeDots } from "react-loader-spinner";
import { toString } from 'lodash';
const Footer = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailflag, setEmailFlag] = useState(true);

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setSuccess('')
    validateEmail(value)
    // CreateFooter(event);
  };

  const validateEmail = (value) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!value) {
      setError('Please enter your email.');
      setEmailFlag(false);
      return false;
    } else if (!emailPattern.test(value)) {
      setError('Invalid email address.');
      setEmailFlag(false)
      return false;
    }
    setError('');
    setEmailFlag(true);
    return true;
  };

  const CreateFooter = (event )  => {
    event.preventDefault(); 
    if( !email){
      toast("Please enter email address", {
        position: "top-right",
        autoClose: 2000,
      })
      return;
    }

     if (!emailflag) {
      toast("Please enter valid email", {
        position: "top-right",
        autoClose: 2000,
      })
      return;
    }
    setLoading(true);
  
    fetch("https://sheetdb.io/api/v1/x2aci6deweftd", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: [
          {
            Sno: "INCREMENT",
            EmailName: email,
          },
        ],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setEmail("");
        toast.success('Email submitted successfully!',{
          position: "top-right",
          autoClose: 2000,
        });
        
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error('Failed to submit email.');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  




  return (
    <>
      <footer className="footer">

        <div className='footer-main' >
          <div class className='footer-first'>
            <div style={{}}>
              <img
                className='footer-first-img'
                alt="..."
                src={require("assets/img/Ellipse 3.png")}
              />
            </div>
            <h2 className='footer-first-h2'>Be updated  <br></br> with <span style={{ fontWeight: '700' }}>trends</span> </h2>
            <p className='footer-first-para'>Catch us when we are giving out information for  <br /> successful business</p>
            <form style={{ display: 'flex' }} >
              <input
                type="email"
                placeholder='Enter your email'
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
                className='footer-input'
              />
              <button onClick={CreateFooter}  disabled={loading} className='footer-button' style={{
                 opacity: loading ? 0.6 : 1,
                 pointerEvents: loading ? 'none' : 'auto',
              }}> { loading ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Bars
                height="25"
                width="40"
                color="white"
                ariaLabel="bars-loading"
                visible={true}
              />
            </div> :
                <img
                  style={{ marginLeft: '8px' }}
                  alt="..."
                  className="path"
                  src={require("assets/img/ArrowUpRight.svg").default}
                />
              }
              </button>
            </form>
            {error && <div style={{ color: 'red', marginTop: '2px', fontSize: '12px', marginLeft: '12px' }}>{error}</div>}
            {success && <div style={{ color: 'green', marginTop: '2px', fontSize: '12px', marginLeft: '12px' }}>{success}</div>}
            <p style={{ color: '#8B8B8B', fontSize: '11px', fontWeight: '400', fontFamily: 'Space Grotesk' }}>I consent to receive updates and contacts from Telemo and confirm that I have read the Privacy policy.</p>

            <br></br>
            <br></br>
            <br></br>

          </div>


          <div className='footer-second'>
            <h2 className='footer-second-heading'>Get In<span style={{ fontWeight: '700' }}>Touch</span> </h2>
            <div className='footer-second-para'> <img alt="..." className="path" src={require("assets/img/MapPinLine.svg").default} /> <p className="pfooter">F-23, F-Block, Sector 11, Noida, Uttar Pradesh, 201301, India</p> </div>
            <div className='footer-second-para1'><img alt="..." className="path" src={require("assets/img/Phone.svg").default} /> <p className="pfooter">+91-120-433-3335</p></div>
            <div className='footer-second-para1'><img alt="..." className="path" src={require("assets/img/Vector (4).svg").default} /> <p className="pfooter">info@startelelogic.com</p> </div>
          </div>

          <div className='footer-third'>

            <div className='third-section'>

              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="blue"
                href="https://www.linkedin.com/company/telemo-io"

                id="tooltip622135962"
                target="_blank"
              >
                <img src={require("assets/img/LinkedinLogo.svg").default} alt="LinkedIn"
                  style={{

                  }} />
              </Button>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="blue"
                href="https://x.com/Telemo_io"
                id="tooltip622135962"
                target="_blank"
              >
                <img src={require("assets/img/TwitterLogo.svg").default} alt="Twitter"
                  style={{

                  }}
                />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip622135962">
                Follow us
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://www.facebook.com/telemo.io"
                id="tooltip230450801"
                target="_blank"
              >
                <img src={require("assets/img/MetaLogo.svg").default} alt="Facebook"
                  style={{

                  }} />
              </Button>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://www.instagram.com/telemo.ai/"
                id="tooltip230450801"
                target="_blank"
              >
                <img src={require("assets/img/InstagramLogo.svg").default} alt="Instagram"
                  style={{

                  }} />
              </Button>

              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://www.youtube.com/channel/UCz587tPFJMAyDYFwUlRtISQ"
                id="tooltip230450801"
                target="_blank"
              >
                <img src={require("assets/img/YoutubeLogo.svg").default} alt="Youtube"
                  style={{

                  }} />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip230450801">
                Like us
              </UncontrolledTooltip>


            </div>
          </div>
          <div className='img-section'>
            <img
              className='footer-img-section'
              alt="..."
              src={require("assets/img/telemo 1 (1).svg").default}

            /></div>

        </div>



      </footer>
      <div style={{
        backgroundColor: 'black',
        height: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontFamily: 'Space Grotesk',
        zIndex: '200',
        cursor: 'pointer'


      }}>
        <p style={{
          padding: '20px',
          fontSize: '16px',
          color: '#FFFFFF',
          marginLeft: '3%',
          fontWeight: '400',
          cursor: 'pointer',
        }} >
          © 2024 Telemo. All rights reserved.
        </p>
        {/* <p style={{
          padding: '20px 80px',
          fontSize: '16px',
          color: '#FFFFFF',
          marginLeft: '20px',
          fontWeight: '400',
          cursor: 'pointer',

        }} >
          Terms of service
          <span style={{ marginLeft: '20px' }}>Privacy Policy</span>
        </p> */}
      </div>

    </>
  );
}

export default Footer;



