
import React, { useEffect} from 'react';


// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import PageHeader from "components/PageHeader/PageHeader.js";
import Footer from "components/Footer/Footer.js";

// sections for this page/view
import Basics from "views/IndexSections/Basics.js";
import Card from "views/IndexSections/Card.jsx";
import FirstProduct from "views/IndexSections/FirstProduct.js";
import Examples from "views/IndexSections/Examples.js";
import AssistanceProduct from './IndexSections/AssistanceProduct';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Index() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  
  return (
    <>
      <PageHeader />
      <IndexNavbar />
      <ToastContainer />
      <div className="wrapper">
      
        <div className="main">
          <Basics />
          <AssistanceProduct />
          <Card />
          <FirstProduct />
          <Examples />
          {/* <Download /> */}
        </div>
        <Footer />
      </div>
    </>
  );
}
