import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// reactstrap components
import {
    Card,
    CardBody,
    Button,
    Collapse,
} from 'reactstrap';

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import TrustedUsers from 'views/IndexSections/TrustedUsers';
import "./Ucaas.css";

const Ucaas = () => {
    const [openId, setOpenId] = useState(null);

    const toggle = (id) => {
        if (openId === id) {
            setOpenId(null);
        } else {
            setOpenId(id);
        }
    };
    const navigate = useNavigate();

    const navigateAi = () => {
        navigate("/ai-agent-development-company")
    }

    const sentimentAnalysis = () => {
        navigate("/sentiment-analysis-development-company")
    }

    const generativeAi = () => {
        navigate("/generative-ai-development-company")
    }
    function handleClick() {
        const purpose = "Become-Partner"
        localStorage.setItem("Purpose", purpose)
        navigate("/contact-us");
    }

    useEffect(() => {
        // Set the document title
        document.title = 'Best UCaaS Development Company | Custom Unified Solutions';
      
      }, []);


 

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ExamplesNavbar />
            <div className="Ucaas-header">
                <div className='Ucaas-main'>
                    <h1 className='Ucaas-heading'>
                        UCaaS

                    </h1>
                    <div className='Ucaas-img'>
                        <img
                        className='Ucaas-mid-img'
                            style={{ width: '51%', }}
                            alt="..."
                            src={require("assets/img/Rectangle 13 (6).svg").default}
                        /></div>
                    <div className='Ucaas-border' >
                        <p className='Ucaas-border-para' >Our UCaaS Solution shatters communication silos, bringing together voice, chat, video conferencing, and file sharing into one unified and ridiculously easy-to-use platform. This translates to streamlined workflows, boosted collaboration across teams, and a dramatically improved customer experience. This fosters collaboration, boosts productivity, and empowers your teams to connect from anywhere on any device.</p>
                    </div>
                    <img
                        className='Ucaas-side-img'
                        alt="..."
                        src={require("assets/img/Rectangle 11 (5).svg").default}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'end', }}>
                    {/* <img
            //  className="rotate-once"
              style={{ width: '28%',marginTop: '-50%' , 
              marginLeft:'0%',
              objectFit: 'cover',
              borderRadius: '20px'}}
              alt="..."
              src={require("assets/img/Vector (6).png")}
           
            /> */}

                </div>
            </div>

            <div className='Ucaas-second-section'>
                <div className='Ucaas-second-main'>
                    {/* Box 1 */}
                    <div className='Ucaas-second-box1' >
                        <h1 className='Ucaas-second-box1-h1' style={{ marginTop: '-20px', fontSize: '56px', fontWeight: '400', fontFamily: 'Space Grotesk' }}>What is UCaaS ?</h1>
                        <img
                            alt="..."
                            src={require("assets/img/Rectangle 16 (5).svg").default}
                            className='Ucaas-second-box1-img'

                        />
                    </div>

                    {/* Box 2 */}
                    <div className='Ucaas-second-box2' >
                        <p className='Ucaas-box2-para1' >
                            UCaaS, or Unified Communications as a Service, acts as a central hub for all your business communication needs. Imagine a single, cloud-based platform that integrates features like voice calls, video conferencing, instant messaging, file sharing, and even email. This eliminates the need for juggling multiple applications and simplifies communication for everyone in your organization. UCaaS empowers seamless collaboration, increased productivity, and a more streamlined workflow – all at a predictable cost.
                        </p>
                        <br />
                        <p className='Ucaas-box2-para1' >
                            Unlike juggling multiple platforms for phone calls, emails, video conferencing, and messaging, Telemo UCaaS solutions bring everything together into a single, cloud-based platform. This translates to a more streamlined workflow for your employees and a more cohesive experience for your customers. With Telemo UCaaS, you can leverage features like instant messaging, video conferencing, presence management, and file sharing – all accessible from a single user interface.
                        </p>

                    </div>
                </div>
            </div>


            <TrustedUsers />

            <div className='Ucaas-forth' >
                <h1 className='Ucaas-forth-h1'> Benefits of Utilizing Our <br /> Comprehensive UCaaS Solutions </h1>

            </div>


            <div className='Ucaas-fifth-section'>
                {/* Box 1 */}
                <div className='Ucaas-fifth-box1' >
                    <img
                        alt="..."
                        src={require("assets/img/Polygon 1.png")}
                    />
                    <h1 >Streamlined Communication and Collaboration</h1>
                    <p > Our UCaaS solutions bring all your communication channels – voice calls, video conferencing, instant messaging, and file sharing – together into a single, unified platform. This eliminates the need to switch between different apps and tools, allowing your teams to collaborate seamlessly and get more done.</p>
                </div>

                {/* Box 2 */}
                <div className='Ucaas-fifth-box2'>
                    <img
                        alt="..."
                        src={require("assets/img/Polygon 1.png")}
                    />

                    <h1>Enhanced Mobility and Flexibility</h1>
                    <p >UCaaS is cloud-based, so your employees can stay connected and access all their communication features from anywhere, on any device. This empowers a remote or hybrid workforce and fosters better work-life balance.</p>
                </div>

                {/* Box 3 */}
                <div className='Ucaas-fifth-box3'>
                    <img
                        alt="..."
                        src={require("assets/img/Polygon 1.png")}
                    />
                    <h1>Increased Productivity</h1>
                    <p>UCaaS streamlines communication workflows and reduces wasted time searching for information or colleagues. Features like presence indicators, instant messaging, and one-click video conferencing help teams connect and resolve issues quickly.</p>
                </div>

                {/* Box 4 */}
                <div className='Ucaas-fifth-box4'>
                    <img
                        alt="..."
                        src={require("assets/img/Polygon 1.png")}
                    />

                    <h1 >Reduced Costs and Scalability</h1>
                    <p >UCaaS eliminates the need for expensive hardware and IT infrastructure. With a subscription-based model, you only pay for the features you need, and you can easily scale your service up or down as your business grows.</p>
                </div>

                <div className='Ucaas-fifth-box5'>
                    <img
                        alt="..."
                        src={require("assets/img/Polygon 1.png")}
                    />

                    <h1>Improved Customer Experience</h1>
                    <p > UCaaS provides tools to enhance customer interactions. Features like call routing, voicemail transcription, and integrated CRM tools allow businesses to deliver a more responsive and personalized customer experience.</p>
                </div>
                <div className='Ucaas-fifth-box6'>
                    <img
                        alt="..."
                        src={require("assets/img/Polygon 1.png")}
                    />

                    <h1 >Enhanced Security and Reliability</h1>
                    <p >Our UCaaS solutions are built with robust security features to protect your communications and data. Additionally, cloud-based platforms offer high availability and disaster recovery capabilities, ensuring your business stays connected even in the event of an outage.</p>
                </div>
            </div>
            <div className='Ucaas-fifth-sec-img' >
                <img
                    alt="..."
                    src={require("assets/img/Okay (2).png")}
                    style={{
                        width: '70%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            </div>

            <div className='Ucaas-sixth-section' >
                <h1 className='Ucaas-sixth-sec-h1'> <span style={{ marginLeft: '5%' }}>Industry Leading </span> <br />UCaaS Solution Services We Offer</h1>
                <p className='Ucaas-sixth-sec-p'>We offer a comprehensive suite of UCaaS solution services designed to cater to your specific requirements and ensure a seamless, secure, and reliable communication experience:</p>
                <div className='Ucaas-sixth-sec-main'>
                    {/* Box 1 */}


                    <div className='Ucaas-sixth-sec-box1'>
                        <div>
                            <div color="primary" onClick={() => toggle(1)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400', cursor: 'pointer', }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                                    <span style={{ color: '#FFFFFF' }}>Customized UCaaS Solutions for Your Needs</span>
                                    <span>{openId === 1 ? '-' : '+'}</span>
                                </div>
                            </div>
                            <Collapse isOpen={openId === 1}>
                                <Card>
                                    <CardBody>
                                        <ul className='bullet-remove'>
                                            <li>Our team of experts will work closely with you to understand your business processes, communication workflows, and specific communication challenges. Based on this in-depth assessment, we'll design a customized UCaaS solution that perfectly aligns with your unique needs and goals.</li>
                                        </ul>
                                    </CardBody>
                                </Card>
                            </Collapse>
                            <div style={{
                                // backgroundColor: 'white',
                                width: '100%',
                                border: '1px solid',
                                borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                                borderImageSlice: 1
                            }} />
                        </div>

                        <div>
                            <div color="primary" onClick={() => toggle(2)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400', cursor: 'pointer', }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                                    <span>  24/7 Support Always at Your Service</span>
                                    <span> {openId === 2 ? '-' : '+'}</span>
                                </div>

                            </div>
                            <Collapse isOpen={openId === 2}>
                                <Card>
                                    <CardBody>
                                        <ul className='bullet-remove'>
                                            <li>We recognize that communication is the lifeline of your business, and that's why we offer 24/7 support. Our dedicated team of UCaaS specialists is always available to address any issues or questions you may have, ensuring that your communication infrastructure remains up and running smoothly, day or night.</li>
                                        </ul>
                                    </CardBody>
                                </Card>
                            </Collapse>
                            <div style={{
                                // backgroundColor: 'white',
                                width: '100%',
                                border: '1px solid',
                                borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                                borderImageSlice: 1
                            }} />
                        </div>

                        <div>
                            <div color="primary" onClick={() => toggle(3)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400', cursor: 'pointer', }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                                    <span>Reliable and Secure</span>
                                    <span> {openId === 3 ? '-' : '+'}</span>
                                </div>

                            </div>
                            <Collapse isOpen={openId === 3}>
                                <Card>
                                    <CardBody>
                                        <ul className='bullet-remove'>
                                            <li>At Telemo we prioritize the security and reliability of our UCaaS solutions. We employ industry-leading security measures to protect your sensitive data and ensure that your communication channels remain secure from unauthorized access or breaches. With Telemo you can rest assured that your communication infrastructure is in safe hands.
                                            </li>
                                        </ul>
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </div>

                    </div>

                    {/* Box 2 */}
                    <div className='Ucaas-sixth-sec-box2'>
                        <img
                            alt="..."
                            src={require("assets/img/Landing Page 1.svg").default}
                            className='Ucaas-sixth-sec-box2-img'
                        
                        />
                    </div>
                </div>
            </div>
            <div className='CC-partner-section-img1'
            >
                <img
                    style={{
                        width: '43%',
                        height: '50%',
                    }}
                    alt="..."
                    src={require("assets/img/Vector.svg").default}
                />
            </div>
            <div className='CC-partner-section-img2'>
                <img
                    style={{
                        width: '10%',
                        height: '50%',
                        margin: '-36% 0% 0% 0%'

                    }}
                    alt="..."
                    src={require("assets/img/Ellipse 7.svg").default}

                />
            </div>

            <div className='CC-partner-section-img3'>
                <img
                    style={{
                        width: '15%',
                        height: '50%',
                        margin: '-33% 0% 0% 0%'
                    }}
                    alt="..."
                    src={require("assets/img/Ellipse 6.svg").default}
                />
            </div>


            <div className="CC-Partner-Section">
                <div className="CC-first-section">

                    <h1>
                        Become a Partner
                    </h1>
                    <div className="glassmorphism" style={{ width: '82%', padding: '20px' }} >
                        <p style={{ textAlign: 'start', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '400' }}>
                            We're actively pursuing partnerships on a global scale. Collaborating with us means forging a strategic alliance aimed at revolutionizing customer experiences and spearheading transformative initiatives for enterprises worldwide. As our esteemed partner, you'll unlock access to state-of-the-art AI technology and a dedicated team fully devoted to your triumph. Together, we'll co-create tailor-made solutions grounded in feedback, unleashing the full potential of your brand, elevating customer satisfaction to unprecedented levels, and driving substantial revenue growth.
                        </p>
                        <button className="button-bts bts" onClick={handleClick} >
                            <img alt="Handshake icon" className="path" src={require("assets/img/Vector (5).svg").default} />
                            <span style={{ marginLeft: '5px', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '500', color: 'rgba(255, 255, 255, 0.8)' }}>Become a Partner</span>
                        </button>
                    </div>
                </div>

                <div className="second-section">
                    <img
                        alt="..."
                        src={require("assets/img/Frame 147.svg").default}
                        style={{
                            width: '100%',
                            height: '60%',
                            objectFit: 'cover',
                            borderRadius: '20px'
                        }}
                    />
                </div>
            </div>
            <div className='Explore-main'>
                <div className='Explore-first'>
                    <button className='Explore-btn'

                    >
                        OUR PRODUCTS
                    </button>

                    <h1 className='explore-h1'>Explore Our <span style={{ fontFamily: 'Playfair Display' }}>Other</span>  Product</h1>
                </div>


                {/* Box 1 */}
                <div className='explore-box1'>

                    <img
                        alt="..."
                        src={require("assets/img/AI-agent1.svg").default}
                        onClick={navigateAi}
                        className='box1-img'
                    />

                </div>

                <div className='explore-box2'>
                    {/* Box 1 */}
                    <div className='box2-img1'>

                        <img
                            className='img1-a'
                            alt="Box 2 Image"
                            src={require("assets/img/Sentiment.svg").default}
                            onClick={sentimentAnalysis}
                        />
                    </div>

                    {/* Box 2 */}
                    <div className='box2-img2'>
                        <img
                            className='img2-a'
                            alt="Box 1 Image"
                            src={require("assets/img/Generative AI.svg").default}
                            onClick={generativeAi}
                        />
                    </div>
                </div>

            </div>
            <Footer />
        </>
    );
}

export default Ucaas
