import React, { useEffect, useRef } from 'react';
import './AssistanceProduct.css'
export default function AssistanceProduct() {
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const tieRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate'); // 'animate' triggers the animation
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the item is in view
    );

    if (leftRef.current) {
      observer.observe(leftRef.current);
    }
    if (rightRef.current) {
      observer.observe(rightRef.current);
    }
    if (tieRef.current) {
      observer.observe(tieRef.current);
    }

    return () => observer.disconnect(); // Cleanup observer on component unmount
  }, []);

  return (
    <>
      <div className='section-product'>

        <div className='award-main'>
          <h1 className='Award-wining'>Award winning Product</h1>
          <h1 className='Award-assistance'> <span style={{ fontFamily: 'Playfair Display' }}>with</span> 24/7 assistance</h1>

          <p className='Award-para'>
            With our advanced AI agents and chatbots, you can offer round-the-clock support to your customers, resolving their queries and issues promptly and efficiently. This not only enhances customer satisfaction but also allows your valuable human resources to focus on more strategic tasks that contribute to your business's success.
          </p>
        </div>

        <div className='img-main'>
          <img
            alt="..."
            src={require("assets/img/Lady.svg").default}
            className='first-imgs'
          />
        </div>

        <div className='tie-img'>
          <img
            alt="..."
            src={require("assets/img/Medal.svg").default}
          className="fadeInRotate"  
          />
        </div>

        <div className='text-card1' >
        <img
            alt="..."
            src={require("assets/img/Frame 47.svg").default}
            style={{ height: '70px', width: '95%', }}
          className="fadeInRight"  
          />
        </div>
        <div className='text-card2'>
        <img
            alt="..."
            src={require("assets/img/Frame 48 (2).svg").default}
            style={{ height: '150px', width: '95%', }}
          className="fadeInRight"  
          />
        </div>



      </div>
    </>

  );
}

