
import React from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col } from "reactstrap";
import './Example.css'

export default function Examples() {
  const navigate = useNavigate();

  function handleClick() {
    const purpose = "Become-Partner"
    localStorage.setItem("Purpose", purpose)
    navigate("/contact-us");
  }
  return (
    <>


      <div className="img-first">
        <img
          style={{
            width: '30%',
            height: '50%',

          }}
          alt="..."
          src={require("assets/img/Ellipse 7.png")}

        />
      </div>

      <div className="img-second">
        <img
          style={{
            width: '35%',
            height: '50%',
          }}
          alt="..."
          src={require("assets/img/Ellipse 6 (1).png")}
        />
      </div>


      <div className="Partner-Section">
        <div className="first-section">

          <h1>
            Become a Partner
          </h1>
          <div className="glassmorphism" style={{  }} >
            <p style={{ textAlign: 'start', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '400' }}>
              We're actively pursuing partnerships on a global scale. Collaborating with us means forging a strategic alliance aimed at revolutionizing customer experiences and spearheading transformative initiatives for enterprises worldwide. As our esteemed partner, you'll unlock access to state-of-the-art AI technology and a dedicated team fully devoted to your triumph. Together, we'll co-create tailor-made solutions grounded in feedback, unleashing the full potential of your brand, elevating customer satisfaction to unprecedented levels, and driving substantial revenue growth.
            </p>
            <button className="button-bts bts" onClick={handleClick} >
              <img alt="Handshake icon" className="path" src={require("assets/img/Vector (5).svg").default} />
              <span style={{ marginLeft: '5px', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '500', color: 'rgba(255, 255, 255, 0.8)' }}>Become a Partner</span>
            </button>
          </div>
        </div>

        <div className="second-section">
          <img
            alt="..."
            src={require("assets/img/Frame 147.svg").default}
            style={{
              width: '100%',
              height: '60%',
              objectFit: 'cover',
              borderRadius: '20px'
            }}
          />
        </div>
      </div>


    </>



  );
}
