import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  Button,
  Collapse,
} from 'reactstrap';



import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import TrustedUsers from 'views/IndexSections/TrustedUsers';
import './CloudTelephony.css';
import PolygonImage from 'assets/img/Polygon 1.png';
import LandingPageImage from 'assets/img/Landing Page 1.svg';

const CloudTelephony = () => {
  const [openId, setOpenId] = useState(null);

  const toggle = (id) => {
    if (openId === id) {
      setOpenId(null);
    } else {
      setOpenId(id);
    }
  };


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate();

  const navigateAi = () => {
    navigate("/ai-agent-development-company")
    window.location.reload();
  }

  const sentimentAnalysis = () => {
    navigate("/sentiment-analysis-development-company")
    window.location.reload();
  }

  const generativeAi = () => {
    navigate("/generative-ai-development-company")
    window.location.reload();
  }

  function handleClick() {
    const purpose = "Become-Partner"
    localStorage.setItem("Purpose", purpose)
    navigate("/contact-us");
  }


  useEffect(() => {
    // Set the document title
    document.title = 'AI-based Cloud Telephony Solution | Cloud Telephony Solution';
  
  }, []);


  return (
    <>
      <ExamplesNavbar />
        <div className="page-header">
          <div className='CT-main'>
            <h1 className='main-title' >
              Cloud <br />
              <span>Telephony</span>
            </h1>
            <div className='CT-img' >
              <img
                style={{ width: '55%', }}
                alt="..."
                src={require("assets/img/Rectangle 13 (4).svg").default}
              /></div>
            <div className='CT-main-border' >
              <p className='CT-main-border-para' >Cloud telephony revolutionizes business phone systems with features like on-demand scalability, cost-effective pay-per-use models, and customizable features like call routing and voicemail. It guarantees reliable service, offers a rich suite of features to enhance customer service, integrates seamlessly with existing applications, and prioritizes security with industry-standard protocols.</p>
            </div>
            <img
              alt="..."
              src={require("assets/img/Rectangle 11 (4).svg").default}
              className='GT-main-side-img'
            />
          </div>

      </div>

      <div className='GT-second' >
        <div className='GT-second-main'>
          {/* Box 1 */}
          <div className='GT-second-box1'>
            <h1 className='GT-second-heading'>What is Cloud <br />Telephony?</h1>
            <img
              alt="..."
              src={require("assets/img/Rectangle 16 (4).svg").default}
              className='GT-secong-box1-img'
            />
          </div>

          {/* Box 2 */}
          <div className='GT-second-box2' >
            <p className='GT-second-box2-text' >
              Cloud telephony is a phone system that runs through your internet connection. It’s also known as a VoIP-based hosted PBX solution. It helps you move your business phone service to the cloud.
            </p>
            <br />
            <p className='GT-second-box2-text'  >
              We are the best Cloud Telephony Service provider in India. Our cloud-based solutions provide a user-friendly online interface for making and receiving calls, managing call routing, and accessing advanced features. This flexibility allows for effortless scaling as your business grows, while simultaneously reducing costs and simplifying management. Our cloud telephony solutions allow businesses to make and receive calls using the internet instead of conventional PSTN or ISDN lines.
            </p>

          </div>
        </div>
      </div>

<TrustedUsers />

   
      <div className='GT-forth-section'>
        <h1 className='GT-forth-section-heading'> <span style={{  }}>Benefits of Using Cloud </span>  <br />Telephony Services from Telemo</h1>

      </div>


      <div className='GT-fifth-section'>
      {/* Box 1 */}
      <div>
        <img alt="..." src={PolygonImage} />
        <h1>Streamlined Communications</h1>
        <p>Eliminate the complexities of traditional phone systems. Telemo cloud-based solution allows for effortless call routing, voicemail management, and presence information, ensuring seamless communication within your team and for your customers.</p>
      </div>

      {/* Box 2 */}
      <div>
        <img alt="..." src={PolygonImage} />
        <h1>Unified Communications</h1>
        <p>Integrate voice calls, video conferencing, instant messaging, and other collaboration tools into a single platform, fostering efficient teamwork and improved customer interactions.</p>
      </div>

      {/* Box 3 */}
      <div>
        <img alt="..." src={PolygonImage} />
        <h1>VoIP Service Provider</h1>
        <p>Benefit from crystal-clear, cost-effective Voice over Internet Protocol (VoIP) calls. Telemo leverages the power of the internet to deliver reliable and secure voice connections, reducing reliance on traditional phone lines.</p>
      </div>

      {/* Box 4 */}
      <div>
        <img alt="..." src={PolygonImage} />
        <h1>Flexible Endpoints</h1>
        <p>Telemo's cloud-based system allows you to make and receive calls using your desktop computer, smartphone, or tablet. This flexibility empowers your team to stay connected and productive regardless of location.</p>
      </div>

      {/* Box 5 */}
      <div className='full-width'>
        <img alt="..." src={PolygonImage} />
        <h1>Online Management</h1>
        <p>Telemo provides a user-friendly online portal for managing all aspects of your phone system, from adding users and assigning extensions to setting call forwarding rules and monitoring call history. This empowers you to customize your service and optimize communication workflows.</p>
      </div>
    </div>

      <div className='CT-sixth-section' >
        <img
          alt="..."
          src={require("assets/img/Okay (2).png")}
          style={{
            width: '70%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </div>

      <div className='GT-container'>
      <h1 className='GT-header'>
       Give a Boost to Your Business with
        Our Comprehensive Cloud Telephony Services
      </h1>
      <div className='GT-content-wrapper'>
        <div className='GT-left-box'>
          <div>
            <div color="primary" onClick={() => toggle(1)} className='GT-collapse-header'>
              <span>Needs Assessment & Cloud Telephony Consulting</span>
              <span>{openId === 1 ? '-' : '+'}</span>
            </div>
            <Collapse isOpen={openId === 1}>
              <Card>
                <CardBody>
                  <ul className='GT-list-style'>
                    <li>Our expert consultants will work closely with you to conduct a thorough communication needs assessment. This involves understanding your current phone system, call volume, communication channels used, and any specific challenges you're facing.</li>
                    <li>Based on this assessment, we'll develop a customized cloud migration strategy. This roadmap outlines the best cloud telephony solution for your business, considering factors like scalability, budget, and feature requirements.</li>
                  </ul>
                </CardBody>
              </Card>
            </Collapse>
            <div className='GT-divider' />
          </div>

          <div>
            <div color="primary" onClick={() => toggle(2)} className='GT-collapse-header'>
              <span>Seamless Implementation & Integration</span>
              <span>{openId === 2 ? '-' : '+'}</span>
            </div>
            <Collapse isOpen={openId === 2}>
              <Card>
                <CardBody>
                  <ul className='GT-list-style'>
                    <li>Our experienced engineers will handle the entire cloud telephony implementation process. This includes system configuration, data migration from your existing phone system, and user provisioning.</li>
                    <li>We ensure seamless integration of your new cloud telephony solution with your existing CRM, ticketing software, and other business applications. This creates a unified communication platform, streamlining workflows and improving communication efficiency.</li>
                  </ul>
                </CardBody>
              </Card>
            </Collapse>
            <div className='GT-divider' />
          </div>

          <div>
            <div color="primary" onClick={() => toggle(3)} className='GT-collapse-header'>
              <span>Comprehensive Training & Ongoing Support</span>
              <span>{openId === 3 ? '-' : '+'}</span>
            </div>
            <Collapse isOpen={openId === 3}>
              <Card>
                <CardBody>
                  <ul className='GT-list-style'>
                    <li>We don't just install the system and leave you hanging. We offer comprehensive cloud telephony user training for your employees, ensuring they feel comfortable and confident using all the features effectively.</li>
                    <li>Our commitment to your success extends beyond the initial setup. We provide 24/7 technical support to address any technical issues or answer your questions promptly.</li>
                  </ul>
                </CardBody>
              </Card>
            </Collapse>
            <div className='GT-divider' />
          </div>

          <div>
            <div color="black" onClick={() => toggle(4)} className='GT-collapse-header'>
              <span>Optimization & Performance Reporting</span>
              <span>{openId === 4 ? '-' : '+'}</span>
            </div>
            <Collapse isOpen={openId === 4}>
              <Card>
                <CardBody>
                  <ul className='GT-list-style'>
                    <li>We go beyond basic implementation by offering ongoing optimization services. We analyze your call data and usage patterns to identify areas for improvement.</li>
                    <li>We leverage cloud telephony analytics to track key metrics like call performance, agent productivity, and customer satisfaction.</li>
                    <li>You'll receive regular reports that provide valuable insights into your communication infrastructure, allowing you to make data-driven decisions for further optimization.</li>
                  </ul>
                </CardBody>
              </Card>
            </Collapse>
          </div>
        </div>

        <div className='GT-right-box'>
          <img alt="..." src={LandingPageImage} />
        </div>
      </div>
    </div>

    
      <div className='CC-partner-section-img1'
       >
          <img
            style={{
              width: '43%',
              height: '50%',
            }}
            alt="..."
            src={require("assets/img/Vector.svg").default}
          />
        </div>
        <div className='CC-partner-section-img2'>
        <img
          style={{
            width: '10%',
            height: '50%',
            margin: '-36% 0% 0% 0%'

          }}
          alt="..."
          src={require("assets/img/Ellipse 7.svg").default}

        />
      </div>

      <div className='CC-partner-section-img3'>
        <img
          style={{
            width: '15%',
            height: '50%',
             margin: '-33% 0% 0% 0%'
          }}
          alt="..."
          src={require("assets/img/Ellipse 6.svg").default}
        />
      </div>


      <div className="CC-Partner-Section">
        <div className="CC-first-section">

          <h1>
            Become a Partner
          </h1>
          <div className="glassmorphism" style={{ width: '82%', padding: '20px' }} >
            <p style={{ textAlign: 'start', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '400' }}>
              We're actively pursuing partnerships on a global scale. Collaborating with us means forging a strategic alliance aimed at revolutionizing customer experiences and spearheading transformative initiatives for enterprises worldwide. As our esteemed partner, you'll unlock access to state-of-the-art AI technology and a dedicated team fully devoted to your triumph. Together, we'll co-create tailor-made solutions grounded in feedback, unleashing the full potential of your brand, elevating customer satisfaction to unprecedented levels, and driving substantial revenue growth.
            </p>
            <button className="button-bts bts" onClick={handleClick} >
              <img alt="Handshake icon" className="path" src={require("assets/img/Vector (5).svg").default} />
              <span style={{ marginLeft: '5px', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '500', color: 'rgba(255, 255, 255, 0.8)' }}>Become a Partner</span>
            </button>
          </div>
        </div>

        <div className="second-section">
          <img
            alt="..."
            src={require("assets/img/Frame 147.svg").default}
            style={{
              width: '100%',
              height: '60%',
              objectFit: 'cover',
              borderRadius: '20px'
            }}
          />
        </div>
      </div>
      <div className='Explore-main'>
        <div className='Explore-first'>
          <button className='Explore-btn'

          >
            OUR PRODUCTS
          </button>

          <h1 className='explore-h1'>Explore Our <span style={{ fontFamily: 'Playfair Display' }}>Other</span>  Product</h1>
        </div>


        {/* Box 1 */}
        <div className='explore-box1'>

          <img
            alt="..."
            src={require("assets/img/AI-agent1.svg").default}
            onClick={navigateAi}
            className='box1-img'
          />

        </div>

        <div className='explore-box2'>
          {/* Box 1 */}
          <div className='box2-img1'>

            <img
              className='img1-a'
              alt="Box 2 Image"
              src={require("assets/img/Sentiment.svg").default}
              onClick={sentimentAnalysis}
            />
          </div>

          {/* Box 2 */}
          <div className='box2-img2'>
            <img
              className='img2-a'
              alt="Box 1 Image"
              src={require("assets/img/Generative AI.svg").default}
              onClick={generativeAi}
            />
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
}

export default CloudTelephony
