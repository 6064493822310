
import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './navbar.css'
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  H1,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Form,
  FormGroup,
  Label,
  Input

} from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Dropdown, } from 'reactstrap';
import { IoMenuOutline } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import { PopupWidget } from "react-calendly";

export default function ExamplesNavbar(...args) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [collapseOut, setCollapseOut] = useState("");
  const [color, setColor] = useState("navbar-transparent");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [resDropdown , setResDropdown] = useState(false);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const toggleResponsive = () => setResDropdown(!resDropdown);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const [modal, setModal] = useState(false);

  const toggles = () => setModal(!modal);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    country: '',
    phoneNumber: ''
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const maxNumber = 11;



  const validate = () => {
    const newErrors = {};
    const disallowedDomains = ["gmail.com", "yahoo.com", "hotmail.com", "rediffmail.com"];

    if (!formValues.firstName) newErrors.firstName = 'First Name is required';
    if (!formValues.lastName) newErrors.lastName = 'Last Name is required';
    if (!formValues.email) {
      newErrors.email = 'Business Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = 'Email address is invalid';
    } else {
      const emailDomain = formValues.email.split('@')[1];
      if (disallowedDomains.includes(emailDomain)) {
        newErrors.email = 'Please enter a bussiness email address';
      }
    }
    if (!formValues.companyName) newErrors.companyName = 'Company Name is required';
    if (!formValues.country) newErrors.country = 'Country is required';

    if (!formValues.phoneNumber) {
      newErrors.phoneNumber = 'Phone Number is required';
    } else {
      const numericValue = formValues.phoneNumber.replace(/[^0-9]/g, ''); // Remove non-numeric characters
      if (numericValue.length !== 10 || numericValue.length <= maxNumber) {
        setFormValues.phoneNumber(numericValue)
        newErrors.phoneNumber = 'Phone number must be exactly 10 digits and only numeric';
      } else if (numericValue !== formValues.phoneNumber) {
        newErrors.phoneNumber = 'Phone number can only contain numeric characters';
      }
    }

    return newErrors;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });

    setErrors({
      ...errors,
      [name]: ''
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitted(true);
      // Handle form submission
      console.log('Form submitted successfully:', formValues);

      // Reset form values
      setFormValues({
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        country: '',
        phoneNumber: ''
      });
    } else {
      setSubmitted(false);
    }
  };

  const handleCancel = () => {
    setFormValues({
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      country: '',
      phoneNumber: ''
    });
    setErrors({});
    toggles();
  };

  const defaultStyle = {
    position: 'relative',
    marginRight: '7%',
    fontSize: '16px',
    color: '#FFFFFF',
    fontWeight: '400',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out'  // Smooth transition for color and transform
  };

  const hoverStyle = {
    ...defaultStyle,
    color: '#ccc',
    textDecoration: 'underline',
    animation: 'shakeX 1s infinite'
  };

  // Toggle function to open/close the dropdown
  const toggle = () => setDropdownOpen(prevState => !prevState);
  React.useEffect(() => {
    window.addEventListener("scroll", changeColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);
  const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      setColor("navbar-transparent");
    }
  };
  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };
  const navigateContactcenter = () => {
    navigate("/contact-center-development-company");
    window.location.reload();
  }
  const navigateCloudPBX = () => {
    navigate("/cloud-pbx-development-company");
    window.location.reload();
  }

  const navigateVoice = () => {
    navigate("/ai-agent-development-company");
    window.location.reload();
  }

  const navigateUcaas = () => {
    navigate("/ucaas-development-company");
    window.location.reload();

  }

  const navigateGenerative = () => {
    navigate("/generative-ai-development-company");
    window.location.reload();
  }

  const navigateOmnichannel = () => {
    navigate("/omnichannel-development-company");
    window.location.reload();
  }

  const navigateCcaas = () => {
    navigate("/ccaas-development-company");
    window.location.reload();
  }

  const navigateCloudTelephony = () => {
    navigate("/cloud-telephony-development-company");
    window.location.reload();
  }

  const navigateSentimentAnalysis = () => {
    navigate("/sentiment-analysis-development-company");
    window.location.reload();
  }

  const navigateChatBot = () => {
    navigate("/chat-bots-development-company");
    window.location.reload();
  }

  const handleClick = () => {
    const purpose = "Contact"
    localStorage.setItem("Purpose", purpose)
    navigate('/contact-us');
    window.location.reload();
  };

  const handleSolution = () => {
    navigate('/custom-ai-development-company');
    window.location.reload();
  }

  const handleClickPartner = () => {
    const purpose = "Become-Partner"
    localStorage.setItem("Purpose", purpose)
    navigate("/contact-us");
    window.location.reload();
  }

  const handleHome = () => {
    navigate("/home");
    window.location.reload();
  }

  return (
    <Navbar className={"fixed-top " + color} color-on-scroll="100" expand="lg" style={{ fontFamily: 'Space Grotesk', width: '100vw',}}>
      <div className="navbar-translate" >
        <Link to="/home" className="navbar-brand" id="navbar-brand" onClick={handleHome}>
          <div className="brand-logo">
            <img
              alt="..."
              src={require("assets/img/telemo 1.svg").default}
            />

          </div>
          <span className="brand-text">Telemo</span>
        </Link>

      </div>

      <Container style={{ justifyContent: 'center', marginLeft: '11%' }}>

        <Collapse
          className={"justify-content-end " + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        // style={{border:'2px solid red',width:'50%'}}
        >

          <Nav navbar className="glassmorphism-nav" style={{
            // border:'2px solid yellow',
            marginRight: '12%',
            fontFamily: 'Space Grotesk',

          }}>

            <NavItem className="p-0" style={{ fontSize: '16px', margin: '8px' }} onClick={handleSolution}>
              Solutions
            </NavItem>
            <NavItem className="p-0" style={{ fontSize: '16px', margin: '2px', }}>
              <Dropdown isOpen={dropdownOpen} toggle={toggle} nav inNavbar>
                <DropdownToggle nav caret>
                  Products
                </DropdownToggle>
                <DropdownMenu middle style={{ borderRadius: '15px', left: '-180px', minWidth: '36vw', marginTop: '15px', fontFamily: 'Space Grotesk', }}>
                  <div style={{ display: 'flex', paddingLeft: '24px', fontWeight: '550', fontSize: '12px', marginTop: '15px', fontFamily: 'Space Grotesk', }}>Our Product</div>
                  <div style={{ display: 'flex', fontFamily: 'Space Grotesk', }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', }}>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk', }} onClick={navigateContactcenter}>
                        <img
                          src={require("assets/img/Headset.svg").default}
                          alt="like1"
                          style={{ marginRight: '10px', width: '24px', height: '24px', }} // Adjust styling as necessary
                        />
                        Contact Center
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '15%' }}>Crafting memorable experiences</p>
                      </DropdownItem>

                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk', }} onClick={navigateCloudPBX}>
                        <img
                          src={require("assets/img/Buildings.svg").default}
                          alt="like1"
                          style={{ marginRight: '10px', width: '24px', height: '24px' }} // Adjust styling as necessary
                        />
                        Cloud PBX
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '15%' }}>Streamlining communication</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk', }} onClick={navigateVoice}>
                        <img
                          src={require("assets/img/Robot.svg").default}
                          alt="like1"
                          style={{ marginRight: '10px', width: '24px', height: '24px' }} // Adjust styling as necessary
                        />
                        AI Agent
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '15%' }}>Powered by intelligence</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk', }} onClick={navigateUcaas}>
                        <img
                          src={require("assets/img/VideoCamera.svg").default}
                          alt="like1"
                          style={{ marginRight: '10px', width: '24px', height: '24px' }} // Adjust styling as necessary
                        />
                        UCaaS
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '15%' }}>Communications with unlimited <br /> possibilities.</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk', marginTop: '-6%' }} onClick={navigateSentimentAnalysis}>
                        <img
                          src={require("assets/img/Vector(1).svg").default}
                          alt="like1"
                          style={{ marginRight: '10px', width: '24px', height: '24px' }} // Adjust styling as necessary
                        />
                        Sentiment Analysis
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '15%' }}>Pulse of your audience</p>
                      </DropdownItem>
                      <div style={{
                        width: '100%',
                        height: '184px',
                        padding: '10px',
                        // border: '2px solid red',
                        margin: '0.5% 0 0 3%',
                        borderRadius: '20px 0px 0px 20px',
                        background: 'rgba(250, 251, 253, 1)'
                      }}>
                        <img
                          alt="..."
                          src={require("assets/img/Frame 147.svg").default}
                          style={{ border: '0.1px gray !important', backgroundColor: 'black', marginLeft: '15px', width: '95%', height: '90%', margin: ' 0 0 7% 0', borderRadius: '10px' }}
                        />
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', }}>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk', }} onClick={navigateCloudTelephony}>
                        <img
                          src={require("assets/img/Cloud.svg").default}
                          alt="Partners"
                          style={{ marginRight: '10px', width: '24px', height: '24px' }} // Adjust styling as necessary
                        />
                        Cloud Telephony
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '15%', width: '90%' }}>Streamlined Communication</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk', }} onClick={navigateOmnichannel}>
                        <img
                          src={require("assets/img/SpinnerGap.svg").default}
                          alt="Partners"
                          style={{ marginRight: '10px', width: '24px', height: '24px' }} // Adjust styling as necessary
                        />
                        Omnichannel
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '15%', width: '90%' }}>Seamless experiences</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk', }} onClick={navigateCcaas}>
                        <img
                          src={require("assets/img/CameraRotate.svg").default}
                          alt="Partners"
                          style={{ marginRight: '10px', width: '24px', height: '24px' }} // Adjust styling as necessary
                        />
                        CCaaS
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '15%', width: '90%' }}>With next-level AI engagement</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk', }} onClick={navigateChatBot}>
                        <img
                          src={require("assets/img/ChatDots.svg").default}
                          alt="Partners"
                          style={{ marginRight: '10px', width: '24px', height: '24px' }} // Adjust styling as necessary
                        />
                        Chatbots
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '15%', width: '90%' }}>Conversations with intelligent</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk', }} onClick={navigateGenerative}>
                        <img
                          src={require("assets/img/Cpu.svg").default}
                          alt="Partners"
                          style={{ marginRight: '10px', width: '24px', height: '24px' }} // Adjust styling as necessary
                        />
                        Generative AI
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '15%', width: '90%' }}>Creativity through limitless</p>
                      </DropdownItem>
                      <div style={{
                        // border: '2px solid red',
                        padding: '10px',
                        margin: '0% 3% 0 0%',
                        borderRadius: '0px 20px 20px 0px',
                        background: 'rgba(250, 251, 253, 1)',
                        paddingLeft: '10%'


                      }}>
                        <div style={{ color: 'rgba(17, 27, 41, 1)', fontWeight: '600', fontSize: '16px', fontFamily: 'Space Grotesk', }} className="partner-text" onClick={handleClickPartner}>Become a Partner</div>
                        <div className="partner-text" onClick={handleClickPartner}>
                          Join Telemo, be an AI innovation partner. Thrive with cutting-edge products, stay ahead in the evolving world of AI, and redefine your future collaboratively.</div>
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            // border:'2px solid red'

                          }}>
                          <p
                            style={{
                              width: '70%',
                              fontSize: '14px',
                              color: '#4c19A1',
                              fontWeight: '550',
                              // marginLeft: '5%',
                              marginTop: '5%',
                              transition: 'transform 0.3s',
                              fontFamily: 'Space Grotesk',
                            }}
                            onClick={handleClickPartner}
                            onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
                            onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                          >
                            <img style={{}} alt="..." className="path" src={require("assets/img/Handshake.svg").default} /> Become A Partner
                          </p>
                          <img
                            alt="..."
                            // className="img-raised"
                            src={require("assets/img/telemo 1 (1).svg").default}
                            style={{
                              width: '32%',
                              height: '35%',

                            }}
                          />

                        </div>
                      </div>


                    </div>
                  </div>
                </DropdownMenu>

              </Dropdown>
            </NavItem>


            <NavItem className="p-0" style={{ fontSize: '16px', margin: '8px' }} onClick={handleClick}>
              Contact Us
            </NavItem>

          </Nav>
          <Nav className='become-partner'
            style={isHovered ? hoverStyle : defaultStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleClickPartner}

          >
            Become a Partner
          </Nav>
          <Nav className='calandy'>
          <PopupWidget
              url="https://calendly.com/amit-ranjan-startelelogic"
              rootElement={document.getElementById("root")}
              text="Get Demo"
              textColor="#ffffff"
              color="#4C19A1"
            />
          </Nav>
        
         
        </Collapse>

        
        <Navbar className='hambugger'>
          <IoMenuOutline onClick={toggleSidebar} />
          {isSidebarOpen && (
            <div className="sidebarItem" style={{ fontFamily: 'Space Grotesk', background: 'rgba(255, 255, 255, 1)', }}>
             


             <Nav navbar style={{ padding:'20px'}} >
              <div className='menu-item' >
                <div style={{ color: 'black', fontWeight: '500', fontSize: '21px', }}>MENU</div>
                <AiOutlineClose style={{ height: '30px', width: '30px', color: 'black' }} onClick={toggleSidebar} />
              </div>
                <NavItem onClick={handleSolution} style={{ fontSize: '21px', fontWeight: '500', color: 'black', margin: '3% 0 0 -2%', }}>
                  Solutions
                </NavItem>

                <Dropdown className='dropdown-item' isOpen={resDropdown} toggle={toggleResponsive} nav inNavbar setAutoClose={true} style={{}}>
                <DropdownToggle nav caret className='nav-drop' style={{ color: 'black', fontSize: '21px', fontWeight: '500', marginLeft: '-2%', lineHeight:'31.5px' }}>
                    Products
                  </DropdownToggle>
                  <DropdownMenu style={{
                     width:'100%',
                     fontFamily: 'Space Grotesk',
                     // display: dropdownOpen ? 'flex' : 'none',
                     flexDirection: 'column',
                     marginLeft: '-2%',
                     background: 'rgba(251, 250, 253, 1)',
                     borderRadius: '10px',
                     // border: '2px solid blue',
                     cursor: 'pointer',
                     zIndex:'initial'
                   }}>
                    <div>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk', }} onClick={navigateContactcenter}>
                        <img
                          src={require("assets/img/Headset.svg").default}
                          alt="like1"
                          style={{ width: '24px', height: '24px', marginRight: '10px', }}
                        />
                        Contact Center
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '11%' }}>Crafting memorable experiences</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk' }} onClick={navigateCloudPBX}>
                        <img
                          src={require("assets/img/Buildings.svg").default}
                          alt="like1"
                          style={{ width: '24px', height: '24px', marginRight: '10px', }}
                        />
                        Cloud PBX
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '11%' }}>Streamlining communication</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk' }} onClick={navigateVoice}>
                        <img
                          src={require("assets/img/Robot.svg").default}
                          alt="like1"
                          style={{ width: '24px', height: '24px', marginRight: '10px', }}
                        />
                        AI Agent
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '11%' }}>Powered by intelligence</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk' }} onClick={navigateUcaas}>
                        <img
                          src={require("assets/img/VideoCamera.svg").default}
                          alt="like1"
                          style={{ width: '24px', height: '24px', marginRight: '10px', }}
                        />
                        UCaaS
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '11%' }}>Communications with unlimited possibilities.</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk', }} onClick={navigateSentimentAnalysis}>
                        <img
                          src={require("assets/img/Vector(1).svg").default}
                          alt="like1"
                          style={{ width: '24px', height: '24px', marginRight: '10px', }}
                        />
                        Sentiment Analysis
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '11%' }}>Pulse of your audience</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk' }} onClick={navigateCloudTelephony}>
                        <img
                          src={require("assets/img/Cloud.svg").default}
                          alt="Partners"
                          style={{ marginRight: '10px', width: '24px', height: '24px' }}
                        />
                        Cloud Telephony
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '11%', }}>Streamlined Communication</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk' }} onClick={navigateOmnichannel}>
                        <img
                          src={require("assets/img/SpinnerGap.svg").default}
                          alt="Partners"
                          style={{ width: '24px', height: '24px', marginRight: '10px', }}
                        />
                        Omnichannel
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '11%', }}>Seamless experiences</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk' }} onClick={navigateCcaas}>
                        <img
                          src={require("assets/img/CameraRotate.svg").default}
                          alt="Partners"
                          style={{ width: '24px', height: '24px', marginRight: '10px', }}
                        />
                        CCaaS
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '11%', }}>With next-level AI engagement</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk' }} onClick={navigateChatBot}>
                        <img
                          src={require("assets/img/ChatDots.svg").default}
                          alt="Partners"
                          style={{ width: '24px', height: '24px', marginRight: '10px', }}
                        />
                        Chatbots
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '11%', }}>Conversations with intelligent</p>
                      </DropdownItem>
                      <DropdownItem style={{ fontWeight: '600', fontSize: '16px', color: 'black', fontFamily: 'Space Grotesk' }} onClick={navigateGenerative}>
                        <img
                          src={require("assets/img/Cpu.svg").default}
                          alt="Partners"
                          style={{ width: '24px', height: '24px', marginRight: '10px', }}
                        />
                        Generative AI
                        <p style={{ color: 'rgba(103, 116, 137, 1)', fontSize: '12px', fontWeight: '500', marginLeft: '11%', }}>Creativity through limitless</p>
                      </DropdownItem>
                    </div>
                  </DropdownMenu>
                </Dropdown>

                <NavItem onClick={handleClick} style={{ color: 'black', fontSize: '21px', fontWeight: '500', marginLeft: '-2%' }}>
                  Contact Us
                </NavItem>
                <div style={{
                  width: '90%',
                  height: 'auto',
                  margin: '3% 0 0 0%',
                  borderRadius: '20px 0px 0px 20px',
                }}>
                  <img
                    alt="..."
                    src={require("assets/img/Frame 147.svg").default}
                    style={{ backgroundSize: 'container', backgroundColor: 'black', width: '95%', height: '100%', borderRadius: '10px', }}
                  />
                </div>

                <div style={{
                  // border: '2px solid red',
                  padding: '10px',
                  margin: '0% 3% 0 0%',
                  borderRadius: '0px 20px 20px 0px',
                  background: 'rgba(250, 251, 253, 1)',
                  // paddingLeft: '10%'


                }}>
                  <div style={{ color: 'rgba(17, 27, 41, 1)', fontWeight: '600', fontSize: '16px', fontFamily: 'Space Grotesk', }} className="partner-text" onClick={handleClickPartner}>Become a Partner</div>
                  <div style={{ width: '90%', fontSize: '14px' }} onClick={handleClickPartner}>
                    Join Telemo, be an AI innovation partner. Thrive with cutting-edge products, stay ahead in the evolving world of AI, and redefine your future collaboratively.</div>
                  <div
                    style={{
                      width: '50%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      // border:'2px solid red'

                    }}>
                    <p
                      style={{
                        width: '100%',
                        fontSize: '14px',
                        color: '#4c19A1',
                        fontWeight: '550',
                        // marginLeft: '5%',
                        marginTop: '5%',
                        transition: 'transform 0.3s',
                        fontFamily: 'Space Grotesk',
                      }}
                      onClick={handleClickPartner}
                      onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
                      onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                    >
                      <img style={{}} alt="..." className="path" src={require("assets/img/Handshake.svg").default} /> Become A Partner
                    </p>


                  </div>
                </div>
              </Nav>

            </div>
          )}
        </Navbar>
      </Container>
    </Navbar>
  );
}
