
import React, { useEffect } from 'react';

const Whatsaap = () => {

  useEffect(() => {
    // Load the Facebook SDK script
    const loadFacebookSDK = () => {
      if (window.FB) {
        window.FB.XFBML.parse();
      } else {
        (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
      }
    };

    // Facebook Pixel Code
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () { n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments) };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    // window.fbq('init', process.env.REACT_APP_FACEBOOK_PIXEL_ID);
    // window.fbq('track', 'PageView');

    loadFacebookSDK();

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '1185099552688320', // Facebook App ID
            cookie: true, // enable cookies
            xfbml: true, // parse social plugins on this page
            version: 'v20.0'
      });

    
    };

    const sessionInfoListener = (event) => {
      if (!event.origin.endsWith("facebook.com")) {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data;
            console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);
          } else if (data.event === 'ERROR') {
            const { error_message } = data.data;
            console.error("error ", error_message);
          } else {
            const { current_step } = data.data;
            console.warn("Cancel at ", current_step);
          }
        }
      } catch {
        console.log('Non JSON Response', event.data);
      }
    };

    window.addEventListener('message', sessionInfoListener);

    return () => {
      window.removeEventListener('message', sessionInfoListener);
    };
  }, []);

  

  const launchWhatsAppSignup = () => {
    if (window.fbq) {
      window.fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: '1185099552688320', feature: 'whatsapp_embedded_signup' });
    }

    window.FB.login(function (response) {
      if (response.authResponse) {
        const code = response.authResponse.code;
        console.log("Login successful with code:", code);
        console.log(response,"----------------------------");
        
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, {
      config_id: '414494531024077',
      response_type: 'code',
      override_default_response_type: true,
      extras: {
        "feature": "whatsapp_embedded_signup",
        "sessionInfoVersion": 3,
        setup: {}
      }
    });
  };

  return (
    <div>
      <h1>Embedded SignUp</h1>
      <button
        onClick={launchWhatsAppSignup}
        style={{
          backgroundColor: '#1877f2',
          border: 0,
          borderRadius: '4px',
          color: '#fff',
          cursor: 'pointer',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontSize: '16px',
          fontWeight: 'bold',
          height: '40px',
          padding: '0 24px'
        }}
      >
        Login with Facebook
      </button>
    </div>
  );
};

export default Whatsaap;
