
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/telemo-ai-site-react.scss";
// import "assets/demo/demo.css";

import Index from "views/Index.js";
import Generative from "views/examples/Generative";
import Omnichannel from "views/examples/Omnichannel";
import CloudTelephony from "views/examples/CloudTelephony";
import SentimentAnalysis from "views/examples/SentimentAnalysis";
import ChatBot from "views/examples/ChatBot";
import ContectUs from "views/examples/ContectUs";
import Solution from "views/examples/Solution";
import WelecomePage from "views/examples/WelcomePage";
import WelcomePage2 from "views/examples/WelcomePage2";
import CloudPBX from "views/examples/CloudPBX";
import Ccaas from "views/examples/Ccaas";
import Ucaas from "views/examples/Ucaas";
import ContactCenter from "views/examples/ContactCenter";
import AiAgent from "views/examples/AiAgent";
import FacebookSDK from "views/examples/FacebookSDK"
import Whatsaap from "views/examples/Whatsaap"

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>

      <Route path="/home" element={<Index />} />
      <Route path="/contact-center-development-company" element={<ContactCenter />} />
      <Route path="/ai-agent-development-company" element={<AiAgent />} />
      <Route path="/ccaas-development-company" element={<Ccaas />} />
      <Route path="/ucaas-development-company" element={<Ucaas />} />
      <Route path="/cloud-pbx-development-company" element={<CloudPBX />} />
      <Route path="/generative-ai-development-company" element={<Generative />} />
      <Route path="/omnichannel-development-company" element={<Omnichannel />} />
      <Route path="/cloud-telephony-development-company" element={<CloudTelephony />} />
      <Route path="/sentiment-analysis-development-company" element={<SentimentAnalysis />} />
      <Route path="/chat-bots-development-company" element={<ChatBot />} />
      <Route path="/contact-us" element={<ContectUs />} />
      <Route path="/custom-ai-development-company" element={<Solution />} />
      <Route path="*" element={<Navigate to="/home" replace />} />
      <Route path="" element={<WelcomePage2 />} />
      <Route path="/welcomePage" element={<WelecomePage />} />
      <Route path="/embeddedSignUp" element={<FacebookSDK />}></Route>
      <Route path="/embeddedSignUps" element={<Whatsaap></Whatsaap>}></Route>
    </Routes>
  </BrowserRouter>
);
