
import React from "react";
// plugin that creates slider
import strip1 from '../../assets/img/Frame 38 A.png'
import strip2 from '../../assets/img/Frame 39A.png'
import './basics.css'

const Basics = () => {

  return (
    <>
      <div className="OurClient">
        <div style={{
          // width: '100%', overflow: 'hidden', border: '2px red solid'
        }}>
          <img src={strip1} id="strip1" />
          <img src={strip1} id="strip2" />
        </div>
      </div>

      <div
        className="basic-img-1">
        <img
          alt="..."
          src={require("assets/img/Ellipse 5.png")}
          className="basic-img"
        />
      </div>
      <div
        className="basic-img-1"
      >
        <img
          alt="..."
          src={require("assets/img/Ellipse 4 (3).png")}
          className="basic-imgs"
        />
      </div>
    </>
  );
}

export default Basics;
