import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  Collapse,
} from 'reactstrap';

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import TrustedUsers from 'views/IndexSections/TrustedUsers';
import "./Genrative.css";

const Genrative = () => {
  const [openId, setOpenId] = useState(null);

  const toggle = (id) => {
    if (openId === id) {
      setOpenId(null);
    } else {
      setOpenId(id);
    }
  };

  const navigate = useNavigate();

  const navigateAi = () => {
    navigate("/ai-agent-development-company")
    window.location.reload();
  }

  const sentimentAnalysis = () => {
    navigate("/sentiment-analysis-development-company")
    window.location.reload();
  }

  const generativeAi = () => {
    navigate("/generative-ai-development-company")
    window.location.reload();
  }


  function handleClick() {
    const purpose = "Become-Partner"
    localStorage.setItem("Purpose", purpose)
    navigate("/contact-us");
  }


  useEffect(() => {
    // Set the document title
    document.title = 'Best Generative AI Development Services | Telemo';
  
  }, []);



  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className='Generative-main' >
            <h1 className='Generative-main-h1'>
              Generative <br />
              <span>AI </span>
            </h1>
            <div className='Generative-main-mid'>
              <img
              className='Generative-main-mid-img'
                alt="..."
                src={require("assets/img/Rectangle 14.svg").default}
              /></div>
            <div className='Generative-main-border'>
              <p className='Generative-main-border-p'>Through the integration of GPT3, GPT 3.5, and GPT 4, we facilitate natural language processing, enabling dynamic conversations and intelligent chatbots to enhance user experiences. Our expertise in DALL-E ensures the creation of visually compelling content, while Whisper enhances speech recognition capabilities, showcasing our commitment to effective communication. With MidJourney, we infuse projects with creativity, and Bard, powered by LaMDA, enables personalized interactions, reflecting our dedication to customer-centric solutions. </p>
            </div>
            <img
              className='Generative-main-img'
              alt="..."
              src={require("assets/img/Rectangle 15 (1).svg").default}
            />
          </div>
       
        </div>

        <div className='Generative-second'>
          <div className='Generative-second-main'>
            {/* Box 1 */}
            <div className='Generative-second-box1'>
              <h1 className='Generative-second-heading' >What is Generative  <br />AI ?</h1>
              <img
                alt="..."
                src={require("assets/img/unsplash1.svg").default}
               className='Generative-second-img'
              />
            </div>

            {/* Box 2 */}
            <div className='Generative-second-box2'>
              <p className='Generative-second-box2-para' >
                Generative AI in Customer Experience (CX) is like having a creative and data-driven assistant for personalizing interactions. It analyzes vast amounts of customer data to understand preferences, predict behavior, and even generate human-like text for chatbots or marketing content. This translates to highly targeted recommendations, more engaging conversations, and ultimately, a more satisfying customer journey.
              </p>
              <br />
              <p className='Generative-second-box2-para' >
                As the Best Generative AI Development Company, we redefine customer interaction dynamics. Generative AI in Customer Experience (CX) acts as a personalized, data-driven assistant, enhancing interactions on a massive scale. At Telemo, we specialize in crafting AI agents that not only understand but also respond to customer inquiries with human-like nuance. Our AI-powered chatbots offer tailored recommendations.
              </p>

            </div>
          </div>
        </div>


        <TrustedUsers />

        <div className='Generative-third'>
          <h1 className='Generative-third-h1'> How You Can Lead the Competition   With Our Generative AI Development Services </h1>

        </div>


        <div className='Generative-forth'>
          {/* Box 1 */}
          <div className='Generative-forth-box1'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />
            <h1 className='Generative-forth-box1-h1' >Enhanced creativity</h1>
            <p className='Generative-forth-box1-para' >With our Generative AI Development Services, unleash your creativity and generate novel ideas effortlessly. Our AI-powered tools assist in brainstorming, concept development, and content creation, helping you stay ahead of the curve and bring innovative solutions to market faster than ever before.</p>
          </div>

          {/* Box 2 */}
          <div className='Generative-forth-box2'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />

            <h1 className='Generative-forth-box2-h1'>Improved productivity</h1>
            <p className='Generative-forth-box2-para'>Boost your team's productivity by automating time-consuming tasks and streamlining processes with our Generative AI solutions. By freeing up valuable time and resources, your team can focus on higher-value strategic initiatives, driving innovation and growth within your organization.</p>
          </div>

          {/* Box 3 */}
          <div className='Generative-forth-box3'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />
            <h1 className='Generative-forth-box3-h1'>Reduced costs</h1>
            <p className='Generative-forth-box3-para'>Minimize expenses associated with content creation, translation services, and manual data analysis through our cost-effective Generative AI development services. By automating repetitive tasks and optimizing workflows, you can achieve significant cost savings while maintaining high-quality outputs.</p>
          </div>

          {/* Box 4 */}
          <div className='Generative-forth-box4'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />

            <h1 className='Generative-forth-box2-h1'>Gain a competitive edge</h1>
            <p className='Generative-forth-box3-para'>Stand out from the competition by leveraging Generative AI to create innovative products, services, and customer experiences. With our tailored AI models and expert guidance, you can differentiate yourself in the market, attract more customers, and drive sustainable business growth.</p>
          </div>
          {/* Box 5 */}
          <div className='Generative-forth-box5'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />
            <h1 className='Generative-forth-box5-h1'>Personalized Customer Experiences</h1>
            <p className='Generative-forth-box5-para'>Deliver personalized customer experiences that drive engagement and loyalty with our Generative AI development services. By analyzing customer data and generating tailored recommendations and content, you can create meaningful interactions that resonate with your audience, leading to increased satisfaction and retention rates.</p>
          </div>

          {/* Box 6 */}
          <div className='Generative-forth-box6'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />

            <h1 className='Generative-forth-box6-h1'>Continuous Innovation</h1>
            <p className='Generative-forth-box6-para'>With our Generative AI solutions, which enable you to continuously iterate and improve your products and services. By leveraging AI-generated insights and ideas, you can fuel a culture of innovation within your organization, driving ongoing growth and success in an ever-evolving marketplace.</p>
          </div>


        </div>

        <div className='Generative-fifth'>
          <img
            alt="..."
            src={require("assets/img/Okay (2).png")}
            className='Generative-fifth-img'
           
          />
        </div>

        <div className='Generative-sixth'>
          <h1 className='Generative-sixth-h1'> Our Generative  <br /> AI Development Services</h1>
          <div className='Generative-sixth-main'>
            {/* Box 1 */}



            <div className='Generative-sixth-box1'>
              <div>
                <div color="primary" onClick={() => toggle(1)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400', cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                    <span> Generative AI Consulting</span>
                    <span>{openId === 1 ? '-' : '+'}</span>
                  </div>
                </div>
                <Collapse isOpen={openId === 1}>
                  <Card>
                    <CardBody>
                      <ul className='bullet-remove'>
                        <li>Our experts will guide you through the exciting possibilities of Generative AI, helping you identify the perfect application for your business goals. Our team of experts will guide you through a personalized consultation. We'll discuss your business goals, identify areas where Generative AI can create a significant impact, and recommend the perfect solution for your unique needs.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />
              </div>

              <div>
                <div color="primary" onClick={() => toggle(2)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400', cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                    <span> AI Model Fine Tuning</span>
                    <span> {openId === 2 ? '-' : '+'}</span>
                  </div>

                </div>
                <Collapse isOpen={openId === 2}>
                  <Card>
                    <CardBody>
                      <ul className='bullet-remove'>
                        <li>We can fine-tune it to your specific needs, making it even more effective. We'll meticulously analyze your model, identify areas for improvement, and fine-tune its parameters to ensure it delivers exceptional results tailored to your specific requirements.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />
              </div>

              <div>
                <div color="primary" onClick={() => toggle(3)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400', cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                    <span>   Generative AI Model Development</span>
                    <span> {openId === 3 ? '-' : '+'}</span>
                  </div>

                </div>
                <Collapse isOpen={openId === 3}>
                  <Card>
                    <CardBody>
                      <ul className='bullet-remove'>
                        <li>Our team of highly skilled developers will work closely with you to understand your vision and craft a custom Generative AI model that perfectly aligns with your unique needs.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />
              </div>
              <div>
                <div color="primary" onClick={() => toggle(4)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400', cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                    <span>Domain-specific Generative AI Model Development</span>
                    <span> {openId === 4 ? '-' : '+'}</span>
                  </div>

                </div>
                <Collapse isOpen={openId === 4}>
                  <Card>
                    <CardBody>
                      <ul className='bullet-remove'>
                        <li>Need an AI that understands your industry? We specialize in building domain-specific models that excel in your field. We understand that different industries have unique needs. That's why we offer domain-specific Generative AI model development. We'll leverage our deep understanding of your industry to build a model that excels in handling your specific data and tasks.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />
              </div>
              <div>
                <div color="primary" onClick={() => toggle(5)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400', cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                    <span>Generative AI Integration</span>
                    <span> {openId === 5 ? '-' : '+'}</span>
                  </div>

                </div>
                <Collapse isOpen={openId === 5}>
                  <Card>
                    <CardBody>
                      <ul className='bullet-remove'>
                        <li>Our team will seamlessly integrate your custom-built AI creation into your existing workflows and systems. This ensures smooth operation and allows you to instantly leverage the power of your AI within your existing infrastructure.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />
              </div>
              <div>
                <div color="primary" onClick={() => toggle(6)} style={{ marginBottom: '1rem', width: '100%', fontSize: '24px', fontWeight: '400', cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', color: '#FFFFFF' }}>
                    <span> Upgrade and Maintenance</span>
                    <span> {openId === 6 ? '-' : '+'}</span>
                  </div>

                </div>
                <Collapse isOpen={openId === 6}>
                  <Card>
                    <CardBody>
                      <ul className='bullet-remove'>
                        <li>Telemo prioritizes keeping you at the forefront of AI technology. As a leader in generative AI consulting, our skilled developers don't just build models, they nurture them. Through our upgrade and maintenance services, we optimize your AI's performance, ensuring it adapts to changing needs and fuels continuous business growth and innovation. This ensures you stay ahead of the curve and constantly unlock new possibilities with your generative AI.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>

            </div>

            {/* Box 2 */}
            <div className='Generative-sixth-box2'>
              <img
                alt="..."
                src={require("assets/img/Landing Page 1.svg").default}
                className='Generative-sixth-box2-img'
                style={{
                  width: '95%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </div>
          </div>
        </div>
        <div className='CC-partner-section-img1'
       >
          <img
            style={{
              width: '43%',
              height: '50%',
            }}
            alt="..."
            src={require("assets/img/Vector.svg").default}
          />
        </div>
        <div className='CC-partner-section-img2'>
        <img
          style={{
            width: '10%',
            height: '50%',
            margin: '-36% 0% 0% 0%'

          }}
          alt="..."
          src={require("assets/img/Ellipse 7.svg").default}

        />
      </div>

      <div className='CC-partner-section-img3'>
        <img
          style={{
            width: '15%',
            height: '50%',
             margin: '-33% 0% 0% 0%'
          }}
          alt="..."
          src={require("assets/img/Ellipse 6.svg").default}
        />
      </div>


      <div className="CC-Partner-Section">
        <div className="CC-first-section">

          <h1>
            Become a Partner
          </h1>
          <div className="glassmorphism" style={{ width: '82%', padding: '20px' }} >
            <p style={{ textAlign: 'start', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '400' }}>
              We're actively pursuing partnerships on a global scale. Collaborating with us means forging a strategic alliance aimed at revolutionizing customer experiences and spearheading transformative initiatives for enterprises worldwide. As our esteemed partner, you'll unlock access to state-of-the-art AI technology and a dedicated team fully devoted to your triumph. Together, we'll co-create tailor-made solutions grounded in feedback, unleashing the full potential of your brand, elevating customer satisfaction to unprecedented levels, and driving substantial revenue growth.
            </p>
            <button className="button-bts bts" onClick={handleClick} >
              <img alt="Handshake icon" className="path" src={require("assets/img/Vector (5).svg").default} />
              <span style={{ marginLeft: '5px', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '500', color: 'rgba(255, 255, 255, 0.8)' }}>Become a Partner</span>
            </button>
          </div>
        </div>

        <div className="second-section">
          <img
            alt="..."
            src={require("assets/img/Frame 147.svg").default}
            style={{
              width: '100%',
              height: '60%',
              objectFit: 'cover',
              borderRadius: '20px'
            }}
          />
        </div>
      </div>
      <div className='Explore-main'>
        <div className='Explore-first'>
          <button className='Explore-btn'

          >
            OUR PRODUCTS
          </button>

          <h1 className='explore-h1'>Explore Our <span style={{ fontFamily: 'Playfair Display' }}>Other</span>  Product</h1>
        </div>


        {/* Box 1 */}
        <div className='explore-box1'>

          <img
            alt="..."
            src={require("assets/img/AI-agent1.svg").default}
            onClick={navigateAi}
            className='box1-img'
          />

        </div>

        <div className='explore-box2'>
          {/* Box 1 */}
          <div className='box2-img1'>

            <img
              className='img1-a'
              alt="Box 2 Image"
              src={require("assets/img/Sentiment.svg").default}
              onClick={sentimentAnalysis}
            />
          </div>

          {/* Box 2 */}
          <div className='box2-img2'>
            <img
              className='img2-a'
              alt="Box 1 Image"
              src={require("assets/img/Generative AI.svg").default}
              onClick={generativeAi}
            />
          </div>
        </div>

      </div>
        <Footer />
      </div>
    </>
  );
}

export default Genrative
