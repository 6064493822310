import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Welcome.css'

const WelecomePage = () => {
    let navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/home');
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className='PageHeaders' >


            <div className='telemo'>
                <div className='telemo-button' >
                <button className="glassmorphism-buttons">WELCOME TO</button>
                </div>
               
                <div className='telemo-sec' >
                <img
                    alt="..."
                    className="telemo-img"
                    src={require("assets/img/telemo 1.svg").default}
                />
                <h1 className='telemo-h1'>Telemo.AI</h1>
                
                </div>
       
            </div>
            <div className='telemo-box' style={{
                display: 'flex',
                paddingLeft:'8%',
               position:'absolute'
            }}>

                <div className='telemo-box1'></div>
                <div className='telemo-box2'>
                </div>




        </div>
        
        </div>
    );
};

export default WelecomePage;














  
  
  
  
  
  
  