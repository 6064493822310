import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// reactstrap components
import {
  Card,
  CardBody,
  Button,
  Collapse,
} from 'reactstrap';

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import TrustedUsers from 'views/IndexSections/TrustedUsers';
import "./Ai-agent.css";

const AIAgent = () => {
  const [openId, setOpenId] = useState(null);

  const toggle = (id) => {
    if (openId === id) {
      setOpenId(null);
    } else {
      setOpenId(id);
    }
  };


  const navigate = useNavigate();

  const navigateAi = () => {
    navigate("/ai-agent-development-company")
    window.location.reload();
  }

  const sentimentAnalysis = () => {
    navigate("/sentiment-analysis-development-company")
    window.location.reload();
  }

  const generativeAi = () => {
    navigate("/generative-ai-development-company")
    window.location.reload();
  }

  function handleClick() {
    const purpose = "Become-Partner"
    localStorage.setItem("Purpose", purpose)
    navigate("/contact-us");
  }

  useEffect(() => {
    // Set the document title
    document.title = 'Leading AI Agent Development Company | Custom AI Agent Solutions';
  }, []);



  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
      <div style={{ backgroundColor: 'black' }}>
        <ExamplesNavbar />
        <div className="wrapper">
          <div className="page-header">
            <div className='Ai-agent'>
              <h1 className='Ai-agent-heading'>
                AI <br />
                <span>Agent</span>
              </h1>
              <div className='Ai-agent-middle'>
                <img
                className='Ai-agent-middle-img'
                  // style={{ width: '55%', }}
                  alt="..."
                  src={require("assets/img/Rectangle 13 (3).svg").default}
                /></div>
              <div className='Ai-agent-border'>
                <p className='Ai-agent-border-p'>Our AI agents offer everything you need to transform customer interactions. With personalized assistance, round-the-clock availability, and support across multiple channels, users get seamless help whenever they need it. Our agents understand natural language, automate tasks, and provide valuable insights, helping businesses make informed decisions and drive innovation.</p>
              </div>
              <img
                className="rotate-once"
                alt="..."
                src={require("assets/img/Rectangle 11 (3).svg").default}
              />

            </div>
            <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
              {/* <img
            //  className="rotate-once"
              style={{ width: '30%',marginTop: '-40%' ,
              marginLeft:'0%',
              objectFit: 'cover',
              borderRadius: '20px'}}
              alt="..."
              src={require("assets/img/Vector (6).png")}
           
            /> */}

            </div>
          </div>

          <div className='Ai-agent-second'>
            <div className='Ai-agent-second-main' >
              {/* Box 1 */}
              <div className='Ai-agent-second-box1'>
                <h1 className='Ai-agent-second-box1-h1' >What is AI Agent?</h1>
                <img
                  alt="..."
                  src={require("assets/img/Rectangle 16 (3).svg").default}
                  className='Ai-agent-second-box1-img'
                />
                <img
                  alt="..."
                  src={require("assets/img/Frame 126 (3).png")}
                  className='Ai-agent-second-box1-img2'
                />
                <img
                  alt="..."
                  src={require("assets/img/Frame 48 (2).png")}
                  className='Ai-agent-second-box1-img3'
                />


              </div>

              {/* Box 2 */}
              <div className='Ai-agent-second-box2'>
                <p className='Ai-agent-second-box2-p' >
                  An AI agent is a computer program designed to act on its own to achieve specific goals. AI agents can perceive their environment, collect data, and use that data to make decisions. Unlike traditional programs that follow a rigid set of instructions, AI agents can learn and adapt over time. This makes them well-suited for tasks that require some level of intelligence and decision-making, such as customer service or fraud detection.
                </p>
                <br />
                <p className='Ai-agent-second-box2-p'>
                  We're at the forefront of developing intelligent AI assistants that can revolutionize how businesses operate across various industries. Imagine these virtual helpers handling customer inquiries, resolving technical glitches, and even optimizing internal systems – all to create a smoother and more efficient experience. By leveraging AI, we can streamline processes, reduce operational costs, and ultimately deliver a superior experience for everyone involved.
                </p>

              </div>
            </div>
          </div>

          <TrustedUsers />


          <div className='Ai-agent-third'>
            <h1 className='Ai-agent-third-h1'> Benefits of Utilizing Our <br /> Comprehensive AI Agent Services </h1>

          </div>


          <div className='Ai-agent-forth'>
            {/* Box 1 */}
            <div className='Ai-forth-box1' >
              <img alt="..." src={require("assets/img/Polygon 1.png")} />
              <h1 className='Ai-forth-box1-h1'>Improved customer service</h1>
              <p className='Ai-forth-box1-p'>AI agents can provide 24/7 customer support and can answer questions and resolve issues quickly and accurately. They are equipped with natural language processing capabilities, allowing them to understand and respond to customer inquiries in a human-like manner. Our AI agents can handle multiple queries simultaneously, reducing wait times and improving overall customer satisfaction.</p>
            </div>

            {/* Box 2 */}
            <div className='Ai-forth-box2'>
              <img alt="..." src={require("assets/img/Polygon 1.png")} />
              <h1 className='Ai-forth-box2-h1'>Reduced costs</h1>
              <p className='Ai-forth-box2-p'>Our AI agents automate repetitive tasks currently handled by human representatives. This frees up your employees for more strategic work, ultimately saving your business money. They are equipped with natural language processing capabilities, allowing them to understand and respond to customer inquiries in a human-like manner.</p>
            </div>

            {/* Box 3 */}
            <div className='Ai-forth-box3'>
              <img alt="..." src={require("assets/img/Polygon 1.png")} />
              <h1 className='Ai-forth-box3-h1'>Increased efficiency</h1>
              <p className='Ai-forth-box3-p'>Our AI agents can work faster and more efficiently than humans, which can improve productivity. They can process large volumes of information quickly, enabling them to handle tasks that would take humans much longer to complete. Their ability to operate without breaks or downtime means that businesses can maintain a high level of operational efficiency around the clock.</p>
            </div>

            {/* Box 4 */}
            <div className='Ai-forth-box4' >
              <img alt="..." src={require("assets/img/Polygon 1.png")} />
              <h1 className='Ai-forth-box4-h1'>Improved decision-making</h1>
              <p className='Ai-forth-box4-p'>Our AI agents analyze vast amounts of customer data to identify trends and patterns that might be missed by humans. These valuable insights can improve your customer service operations and guide better business decisions.</p>
            </div>
          </div>

          <div className='Ai-agent-fifth'>
            <img
              alt="..."
              src={require("assets/img/Okay (2).png")}
              className='Ai-agent-fifth-img'
            />
          </div>

          <div className='Ai-agent-sixth'>
            <h1 className='Ai-agent-sixth-h1'> AI Agent    <br /> Development Services We Offer</h1>
            <p className='Ai-agent-sixth-p' >Telemo empowers you to build next-generation AI agents that transform your customer interactions and streamline operations. We offer a comprehensive suite of services designed to meet your specific needs:</p>
            <div className='Ai-agent-container'>
              {/* Box 1 */}
              <div className='container-box1'>
                <div>
                  <div className='toggle-section' onClick={() => toggle(1)}>
                    <div className='toggle-header'>
                      <span>Intelligent Chatbots</span>
                      <span>{openId === 1 ? '-' : '+'}</span>
                    </div>
                  </div>
                  <Collapse isOpen={openId === 1}>
                    <Card>
                      <CardBody>
                        <ul className='list-style'>
                          <li>Craft engaging and informative chatbots that seamlessly answer customer queries, troubleshoot issues, and provide 24/7 support on your website or app.</li>
                          <li>Leverage our advanced Natural Language Processing (NLP) to ensure your chatbots understand even complex user inquiries and respond in a natural, conversational way.</li>
                          <li>Integrate them with your CRM system to personalize interactions, access customer history, and offer targeted solutions.</li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Collapse>
                  <div className='divider' />
                </div>

                <div>
                  <div className='toggle-section' onClick={() => toggle(2)}>
                    <div className='toggle-header'>
                      <span> Personalized Virtual Assistants</span>
                      <span>{openId === 2 ? '-' : '+'}</span>
                    </div>
                  </div>
                  <Collapse isOpen={openId === 2}>
                    <Card>
                      <CardBody>
                        <ul className='list-style'>
                          <li>Empower your users with intuitive virtual assistants that become their digital companions.</li>
                          <li>Automate daily tasks and enhance productivity with features like appointment scheduling, travel arrangements, and financial management.</li>
                          <li>Leverage machine learning to personalize the user experience, offering proactive suggestions and reminders based on individual needs and preferences.</li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Collapse>
                  <div className='divider' />
                </div>

                <div>
                  <div className='toggle-section' onClick={() => toggle(3)}>
                    <div className='toggle-header'>
                      <span>  Smart Recommender Systems</span>
                      <span>{openId === 3 ? '-' : '+'}</span>
                    </div>
                  </div>
                  <Collapse isOpen={openId === 3}>
                    <Card>
                      <CardBody>
                        <ul className='list-style'>
                          <li>Drive customer engagement and boost sales with intelligent recommender systems.</li>
                          <li>Analyze user behavior and preferences to suggest relevant products or services with pinpoint accuracy.</li>
                          <li>Enhance the customer journey by offering personalized recommendations across touchpoints, from your website to email marketing campaigns.</li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Collapse>
                  <div className='divider' />
                </div>

                <div>
                  <div className='toggle-section' onClick={() => toggle(4)}>
                    <div className='toggle-header'>
                      <span> Advanced Fraud Detection Systems</span>
                      <span>{openId === 4 ? '-' : '+'}</span>
                    </div>
                  </div>
                  <Collapse isOpen={openId === 4}>
                    <Card>
                      <CardBody>
                        <ul className='list-style'>
                          <li>Safeguard your business and customers with robust AI-powered fraud detection.</li>
                          <li>Our intelligent agents analyze transactions in real-time, identifying suspicious patterns and anomalies that might indicate fraudulent activity.</li>
                          <li>Mitigate financial losses and protect your brand reputation with proactive fraud detection solutions.</li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </div>

              {/* Box 2 */}
              <div className='container-box2'>
                <img
                  alt="..."
                  src={require("assets/img/Landing Page 1.svg").default}
                />
              </div>
            </div>

          </div>

          <div className='CC-partner-section-img1'
          >
            <img
              style={{
                width: '43%',
                height: '50%',
              }}
              alt="..."
              src={require("assets/img/Vector.svg").default}
            />
          </div>
          <div className='CC-partner-section-img2'>
            <img
              style={{
                width: '10%',
                height: '50%',
                margin: '-36% 0% 0% 0%'

              }}
              alt="..."
              src={require("assets/img/Ellipse 7.svg").default}

            />
          </div>

          <div className='CC-partner-section-img3'>
            <img
              style={{
                width: '15%',
                height: '50%',
                margin: '-33% 0% 0% 0%'
              }}
              alt="..."
              src={require("assets/img/Ellipse 6.svg").default}
            />
          </div>



          <div className="CC-Partner-Section">
            <div className="CC-first-section">

              <h1>
                Become a Partner
              </h1>
              <div className="glassmorphism" style={{ width: '82%', padding: '20px' }} >
                <p style={{ textAlign: 'start', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '400' }}>
                  We're actively pursuing partnerships on a global scale. Collaborating with us means forging a strategic alliance aimed at revolutionizing customer experiences and spearheading transformative initiatives for enterprises worldwide. As our esteemed partner, you'll unlock access to state-of-the-art AI technology and a dedicated team fully devoted to your triumph. Together, we'll co-create tailor-made solutions grounded in feedback, unleashing the full potential of your brand, elevating customer satisfaction to unprecedented levels, and driving substantial revenue growth.
                </p>
                <button className="button-bts bts" onClick={handleClick} >
                  <img alt="Handshake icon" className="path" src={require("assets/img/Vector (5).svg").default} />
                  <span style={{ marginLeft: '5px', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '500', color: 'rgba(255, 255, 255, 0.8)' }}>Become a Partner</span>
                </button>
              </div>
            </div>

            <div className="second-section">
              <img
                alt="..."
                src={require("assets/img/Frame 147.svg").default}
                style={{
                  width: '100%',
                  height: '60%',
                  objectFit: 'cover',
                  borderRadius: '20px'
                }}
              />
            </div>
          </div>
          <div className='Explore-main'>
            <div className='Explore-first'>
              <button className='Explore-btn'

              >
                OUR PRODUCTS
              </button>

              <h1 className='explore-h1'>Explore Our <span style={{ fontFamily: 'Playfair Display' }}>Other</span>  Product</h1>
            </div>


            {/* Box 1 */}
            <div className='explore-box1'>

              <img
                alt="..."
                src={require("assets/img/AI-agent1.svg").default}
                onClick={navigateAi}
                className='box1-img'
              />

            </div>

            <div className='explore-box2'>
              {/* Box 1 */}
              <div className='box2-img1'>

                <img
                  className='img1-a'
                  alt="Box 2 Image"
                  src={require("assets/img/Sentiment.svg").default}
                  onClick={sentimentAnalysis}
                />
              </div>

              {/* Box 2 */}
              <div className='box2-img2'>
                <img
                  className='img2-a'
                  alt="Box 1 Image"
                  src={require("assets/img/Generative AI.svg").default}
                  onClick={generativeAi}
                />
              </div>
            </div>

          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default AIAgent;
