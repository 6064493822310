import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  Button,
  Collapse,
} from 'reactstrap';

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import TrustedUsers from 'views/IndexSections/TrustedUsers';
import "./Ccaas.css";

const Ccaas = () => {

  const [openId, setOpenId] = useState(null);

  const toggle = (id) => {
    if (openId === id) {
      setOpenId(null);
    } else {
      setOpenId(id);
    }
  };
  const navigate = useNavigate();

  const navigateAi = () => {
    navigate("/ai-agent-development-company")
    window.location.reload();
  }

  const sentimentAnalysis = () => {
    navigate("/sentiment-analysis-development-company")
    window.location.reload();
  }

  const generativeAi = () => {
    navigate("/generative-ai-development-company")
    window.location.reload();
  }


  function handleClick() {
    const purpose = "Become-Partner"
    localStorage.setItem("Purpose", purpose)
    navigate("/contact-us");
  }

  useEffect(() => {
    // Set the document title
    document.title = 'Best CCaaS Development Company | Custom Solutions';
  }, []);



  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
      <div style={{ backgroundColor: 'black' }}>
        <ExamplesNavbar />
        <div className="page-header">
          <div className='Ccaas-main' >
            <h1 className='Ccaas-heading'>
              CCaaS

            </h1>
            <div className='Ccaas-middle' >
              <img
                className='Ccaas-middle-img'
                alt="..."
                src={require("assets/img/Rectangle 13 (5).svg").default}
              /></div>
            <div className='Ccaas-border' >
              <p className='Ccaas-border-p' >Our cloud-based solution empowers businesses to eliminate the need for on-premise infrastructure, fostering unparalleled scalability and cost-efficiency. Telemo CCaaS grants granular control over call routing, automates workflows through integrations with existing CRMs, and prioritizes robust security measures. By leveraging our CCaaS services, enterprises can ensure exceptional customer experiences through a reliable and feature-rich platform.</p>
            </div>
            <img
              className='Ccaas-end'
              alt="..."
              src={require("assets/img/Rectangle 14 (1).svg").default}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'end', }}>
            {/* <img
            //  className="rotate-once"
              style={{ width: '28%',marginTop: '-50%' , 
              marginLeft:'0%',
              objectFit: 'cover',
              borderRadius: '20px'}}
              alt="..."
              src={require("assets/img/Vector (6).png")}
           
            /> */}
          </div>
        </div>

        <div className='Ccaas-second' >
          <div className='Ccaas-second-main' >
            {/* Box 1 */}
            <div className='Ccaas-second-box1' >
              <h1 className='Ccaas-second-box1-h1'>What is CCaaS ?</h1>
              <img
                alt="..."
                src={require("assets/img/Rectangle 16 (7).svg").default}
                className='Ccaas-second-box1-img'

              />
            </div>

            {/* Box 2 */}
            <div className='Ccaas-second-box2'>
              <p className='Ccaas-second-box2-p'>
                CCaaS stands for Contact Center as a Service. Imagine a cloud-based solution that handles all your customer interactions across various channels like phone calls, emails, chats, and social media. That's the magic of CCaaS! It eliminates the need for expensive on-site hardware and IT support. With CCaaS, businesses can route inquiries to the right agent, leverage features like call recording and analytics, and ultimately deliver a smoother customer experience – all at a predictable cost.
              </p>
              <br />
              <p className='Ccaas-second-box2-p'>
                At Telemo, we understand that every business has unique communication needs. That's why we offer a comprehensive suite of CCaaS solution designed to cater to your specific requirements and ensure a seamless, secure, and reliable communication experience.
              </p>

            </div>
          </div>
        </div>



        <TrustedUsers />

        <div className='Ccaas-third'>
          <h1 className='Ccaas-third-h1'> Why to Choose Our CCaaS Solutions </h1>

        </div>


        <div className='Ccaas-forth' >
          {/* Box 1 */}
          <div className='Ccaas-forth-box1' >
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />
            <h1 className='Ccaas-foth-box1-h1' >Enhanced Customer Satisfaction</h1>
            <p className='Ccaas-forth-box1-p' >CCaaS empowers businesses to cater to customer preferences and deliver a consistent, seamless experience. Additionally, CRM integrations enable personalized interactions, where agents can leverage customer data to address them by name, understand their history, and provide targeted solutions, fostering stronger relationships.</p>
          </div>

          {/* Box 2 */}
          <div className='Ccaas-forth-box2'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />

            <h1 className='Ccaas-forth-box2-h1' >Reduced Costs</h1>
            <p className='Ccaas-forth-box2-p'>Cloud-based contact centers (CCaaS) deliver cost-effectiveness through a pay-as-you-go model, eliminating upfront hardware and software expenses. This flexibility extends to scaling your solution up or down based on call volume, ensuring you optimize costs during seasonal fluctuations.This efficiency is further enhanced by features that optimize agent workflows, such as call routing, skills-based matching, and call recording, minimizing wasted time and reducing overall labor costs.</p>
          </div>

          {/* Box 3 */}
          <div className='Ccaas-forth-box3'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />
            <h1 className='Ccaas-forth-box3-h1'>Boosted Agent Productivity</h1>
            <p className='Ccaas-forth-box3-p'>Cloud contact centers elevate agent productivity and satisfaction through a unified interface. This single platform consolidates all communication channels and customer data, eliminating the need to switch between applications and simplifying tasks. Additionally, real-time performance monitoring empowers managers with call analytics and reporting to identify areas for improvement.</p>
          </div>

          {/* Box 4 */}
          <div className='Ccaas-forth-box4' >
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />

            <h1 className='Ccaas-forth-box4-h1'>Enhanced Agility</h1>
            <p className='Ccaas-forth-box4-p'>CCaaS offers a cloud-based advantage with scalability on demand, allowing you to easily adapt your contact center operations to changing business needs. This means you can quickly add or remove agents and features as your customer base or call volume fluctuates, ensuring you're always prepared. Additionally, faster deployment gets your contact center up and running in a matter of days compared to on-premise systems.</p>
          </div>
        </div>


        <div className='Ccaas-fifth'>
          <img
            alt="..."
            src={require("assets/img/Okay (2).png")}
            className='Ccaas-fifth-img'
          />
        </div>

        <div className='Ccaas-sixth'>
          <h1 className='Ccaas-sixth-h1'> Our Services in CCaaS Solution</h1>
          <p className='Ccaas-sixth-p'>We offer a comprehensive suite of CCaaS services to empower your business with exceptional customer service capabilities. Our services encompass:</p>

          <div className='Ccaas-container'>
            {/* Box 1 Container */}
            <div className='Ccaas-box1-container'>
              <div>
                <div className='Ccaas-box-title' onClick={() => toggle(1)}>
                  <div className='Ccaas-box-title-text'>
                    <span>CCaaS Implementation</span>
                    <span>{openId === 1 ? '-' : '+'}</span>
                  </div>
                </div>
                <Collapse isOpen={openId === 1}>
                  <Card>
                    <CardBody>
                      <ul className='Ccaas-bullet-remove'>
                        <li>Our team of experts will guide you through the entire CCaaS implementation process, from selecting the right solution to configuring and deploying it seamlessly within your existing IT infrastructure. We'll work closely with you to understand your unique business needs and goals, ensuring that the chosen CCaaS solution aligns perfectly with your vision.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div className='Ccaas-divider' />
              </div>

              <div>
                <div className='Ccaas-box-title' onClick={() => toggle(2)}>
                  <div className='Ccaas-box-title-text'>
                    <span>CCaaS Integration</span>
                    <span>{openId === 2 ? '-' : '+'}</span>
                  </div>
                </div>
                <Collapse isOpen={openId === 2}>
                  <Card>
                    <CardBody>
                      <ul className='Ccaas-bullet-remove'>
                        <li>We specialize in integrating your CCaaS solution with your existing CRM, ERP, and other business systems, creating a unified platform for streamlined customer relationship management. This integration enables seamless data sharing and collaboration across departments, empowering you to deliver a truly personalized and consistent customer experience.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div className='Ccaas-divider' />
              </div>

              <div>
                <div className='Ccaas-box-title' onClick={() => toggle(3)}>
                  <div className='Ccaas-box-title-text'>
                    <span>CCaaS Training</span>
                    <span>{openId === 3 ? '-' : '+'}</span>
                  </div>
                </div>
                <Collapse isOpen={openId === 3}>
                  <Card>
                    <CardBody>
                      <ul className='Ccaas-bullet-remove'>
                        <li>We provide comprehensive training programs for your agents, equipping them with the knowledge and skills to effectively utilize the CCaaS solution. Our training covers all aspects of the platform, from handling customer inquiries to leveraging advanced features for enhanced productivity.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div className='Ccaas-divider' />
              </div>

              <div>
                <div className='Ccaas-box-title' onClick={() => toggle(4)}>
                  <div className='Ccaas-box-title-text'>
                    <span>CCaaS Support</span>
                    <span>{openId === 4 ? '-' : '+'}</span>
                  </div>
                </div>
                <Collapse isOpen={openId === 4}>
                  <Card>
                    <CardBody>
                      <ul className='Ccaas-bullet-remove'>
                        <li>Our dedicated support team is available 24/7 to provide prompt and expert assistance whenever you need it. We'll be your trusted partners, ensuring that your CCaaS solution continues to operate smoothly and efficiently, maximizing its value for your business.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div>

            {/* Box 2 Container */}
            <div className='Ccaas-box2-container'>
              <img
                alt="..."
                src={require("assets/img/Landing Page 1.svg").default}
              />
            </div>
          </div>

        </div>
        <div className='CC-partner-section-img1'
        >
          <img
            style={{
              width: '43%',
              height: '50%',
            }}
            alt="..."
            src={require("assets/img/Vector.svg").default}
          />
        </div>
        <div className='CC-partner-section-img2'>
          <img
            style={{
              width: '10%',
              height: '50%',
              margin: '-36% 0% 0% 0%'

            }}
            alt="..."
            src={require("assets/img/Ellipse 7.svg").default}

          />
        </div>

        <div className='CC-partner-section-img3'>
          <img
            style={{
              width: '15%',
              height: '50%',
              margin: '-33% 0% 0% 0%'
            }}
            alt="..."
            src={require("assets/img/Ellipse 6.svg").default}
          />
        </div>



        <div className="CC-Partner-Section">
          <div className="CC-first-section">

            <h1>
              Become a Partner
            </h1>
            <div className="glassmorphism" style={{ width: '82%', padding: '20px' }} >
              <p style={{ textAlign: 'start', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '400' }}>
                We're actively pursuing partnerships on a global scale. Collaborating with us means forging a strategic alliance aimed at revolutionizing customer experiences and spearheading transformative initiatives for enterprises worldwide. As our esteemed partner, you'll unlock access to state-of-the-art AI technology and a dedicated team fully devoted to your triumph. Together, we'll co-create tailor-made solutions grounded in feedback, unleashing the full potential of your brand, elevating customer satisfaction to unprecedented levels, and driving substantial revenue growth.
              </p>
              <button className="button-bts bts" onClick={handleClick} >
                <img alt="Handshake icon" className="path" src={require("assets/img/Vector (5).svg").default} />
                <span style={{ marginLeft: '5px', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '500', color: 'rgba(255, 255, 255, 0.8)' }}>Become a Partner</span>
              </button>
            </div>
          </div>

          <div className="second-section">
            <img
              alt="..."
              src={require("assets/img/Frame 147.svg").default}
              style={{
                width: '100%',
                height: '60%',
                objectFit: 'cover',
                borderRadius: '20px'
              }}
            />
          </div>
        </div>
        <div className='Explore-main'>
          <div className='Explore-first'>
            <button className='Explore-btn'

            >
              OUR PRODUCTS
            </button>

            <h1 className='explore-h1'>Explore Our <span style={{ fontFamily: 'Playfair Display' }}>Other</span>  Product</h1>
          </div>


          {/* Box 1 */}
          <div className='explore-box1'>

            <img
              alt="..."
              src={require("assets/img/AI-agent1.svg").default}
              onClick={navigateAi}
              className='box1-img'
            />

          </div>

          <div className='explore-box2'>
            {/* Box 1 */}
            <div className='box2-img1'>

              <img
                className='img1-a'
                alt="Box 2 Image"
                src={require("assets/img/Sentiment.svg").default}
                onClick={sentimentAnalysis}
              />
            </div>

            {/* Box 2 */}
            <div className='box2-img2'>
              <img
                className='img2-a'
                alt="Box 1 Image"
                src={require("assets/img/Generative AI.svg").default}
                onClick={generativeAi}
              />
            </div>
          </div>

        </div>
        <Footer />

      </div>
    </>
  );
}

export default Ccaas
