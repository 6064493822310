import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  Button,
  Collapse,
} from 'reactstrap';

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import TrustedUsers from 'views/IndexSections/TrustedUsers';
import "./CloudPBX.css";


const CloudPBX = () => {

  const [openId, setOpenId] = useState(null);

  const toggle = (id) => {
    if (openId === id) {
      setOpenId(null);
    } else {
      setOpenId(id);
    }
  };

  const navigate = useNavigate();

  const navigateAi = () => {
    navigate("/ai-agent-development-company")
    window.location.reload();
  }

  const sentimentAnalysis = () => {
    navigate("/sentiment-analysis-development-company")
    window.location.reload();
  }

  const generativeAi = () => {
    navigate("/generative-ai-development-company")
    window.location.reload();
  }

  function handleClick() {
    const purpose = "Become-Partner"
    localStorage.setItem("Purpose", purpose)
    navigate("/contact-us");
  }


  useEffect(() => {
    // Set the document title
    document.title = 'Best AI Cloud PBX Provider | Hosted PBX Solutions';
  
  }, []);




  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className='CP-main'>
            <h1 className='CP-main-heading'>
              Cloud <br />
              PBX
            </h1>
            <div className='CP-main-middle' >
              <img
              className='CP-main-middle-img'
                alt="..."
                src={require("assets/img/Rectangle 13 (7).svg").default}
              /></div>
            <div className='CP-main-border'>
              <p className='CP-main-border-p'>Cloud PBX revolutionizes business phone systems with scalability to effortlessly adapt to your growth, cost-effectiveness through pay-per-use models, and flexibility to customize features like call routing and voicemail. It ensures reliable service with redundant data centers, offers a rich feature set for enhanced customer service, integrates seamlessly with existing business tools, and prioritizes security with industry-standard protocols.</p>
            </div>
            <img
            className='CP-main-end'
              alt="..."
              src={require("assets/img/Rectangle 14 (2).svg").default}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'end', width: '100%', height: '60%' }}>
            {/* <img
              style={{
                width: '28%',
                // height: '35%', 
                marginTop: '-50%',
                marginLeft: '0%',
                objectFit: 'cover',
                borderRadius: '20px'
              }}
              alt="..."
              src={require("assets/img/Vector (6).png")}
            /> */}

          </div>
        </div>


        <div className='Cp-second-section'>
          <div className='Cp-second-main'>
            {/* Box 1 */}
            <div className='Cp-second-box1' >
              <h1 className='cp-box1-heading'>What is Cloud PBX ?</h1>
              <img
                alt="..."
                src={require("assets/img/Rectangle 16 (6).svg").default}
                className='cp-box1-img1'
              
              />

              <img
                alt="..."
                src={require("assets/img/Frame 126.png")}
                className='cp-box1-img2'
             
              />

              <img
                alt="..."
                src={require("assets/img/Frame 126 (4).png")}
                className='cp-box1-img3'
             
              />
            </div>

            {/* Box 2 */}
            <div className='Cp-second-box2'>
              <p className='cp-box2-p'>
                Cloud PBX, also known as hosted PBX or virtual PBX, is a business phone system that operates entirely over the internet. Unlike traditional PBX systems that require on-site hardware, cloud PBX lives in a remote data center managed by a service provider. This eliminates the need for your company to purchase and maintain expensive equipment.
              </p>
              <br />
              <p className='cp-box2-p'>
                We are a leading provider of Cloud PBX services, revolutionizing the way businesses manage their communications. Our Cloud PBX services offer seamless integration with existing systems, advanced call management features, and superior reliability, ensuring that businesses can maintain uninterrupted, high-quality communication with clients and colleagues alike. With a focus on innovation and customer satisfaction, we empower organizations to streamline their operations, enhance productivity, and stay ahead in an increasingly competitive market.
              </p>

            </div>
          </div>
        </div>

        {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <TrustedUsers />

        {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        <div className='CP-third'>
          <h1 className='CP-third-h1'> What Can Our <br /> Cloud PBX Solutions Do? </h1>

        </div>


        <div className='CP-forth'>
          {/* Box 1 */}
          <div className='CP-forth-box1'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />
            <h1 className='CP-forth-box1-h1'>Improved communication</h1>
            <p className='CP-forth-box1-para'>Effortlessly route calls to the perfect destination with pre-set rules, eliminating missed calls and wasted transfers. Unify your communication channels by integrating voice calls, video conferencing, instant messaging, and even faxing into one user-friendly platform. Collaborate and connect with colleagues and clients seamlessly, all while enjoying crystal-clear conversations thanks to Voice over Internet Protocol (VoIP) technology. With Telemo, clear communication is effortless.</p>
          </div>

          {/* Box 2 */}
          <div className='CP-forth-box2'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />

            <h1 className='CP-forth-box2-h1' >Increased productivity</h1>
            <p className='CP-forth-box2-para' >Make and receive calls on the go, boosting employee flexibility and responsiveness. This goes beyond basic calling by offering a suite of collaboration features like call forwarding, conferencing, and voicemail transcription, streamlining communication and freeing up valuable time. Plus, automated features like auto-attendants and call menus further enhance efficiency by directing calls and simplifying call handling, empowering your team to focus on strategic work.</p>
          </div>

          {/* Box 3 */}
          <div className='CP-forth-box3'>
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />
            <h1 className='CP-forth-box3-h1'>Reduced costs</h1>
            <p className='CP-forth-box3-para'>Cloud PBX offers a cost-effective business communication solution by eliminating upfront hardware expenses and ongoing maintenance needs for phone lines and PBX systems. This cloud-based solution allows you to scale your service easily, paying only for the features and phone lines you need as your business grows. Additionally, Cloud PBX providers often have competitive rates on domestic and international calls, further reducing your communication costs.</p>
          </div>

          {/* Box 4 */}
          <div className='CP-forth-box4' >
            <img
              alt="..."
              src={require("assets/img/Polygon 1.png")}
            />

            <h1 className='CP-forth-box4-h1' >Enhanced flexibility</h1>
            <p className='CP-forth-box3-para'>Cloud PBX offers a trifecta of benefits for businesses with a mobile workforce: it empowers remote employees to work seamlessly from anywhere with an internet connection, ensures disaster recovery with its inherent cloud-based nature, and provides future-proof technology with constant evolution and feature updates without hardware upgrades.</p>
          </div>
        </div>
        <div className='CP-fifth'>
          <img
            alt="..."
            src={require("assets/img/Okay (2).png")}
            className='CP-fifth-img'
          />
        </div>

        <div className='CP-sixth'>
          <h1 className='CP-sixth-h1'> <span style={{ marginLeft: '12%' }}>Our Services in</span>  <br /> Cloud PBX Solution</h1>
          <p className='CP-sixth-p' >We offer a comprehensive suite of cloud PBX solutions to meet the unique needs of your business. Our services include:</p>
          <div className='CP-sixth-main'>
            {/* Box 1 */}


            <div className='CP-sixth-box1' >
              <div>
                <div color="primary" onClick={() => toggle(1)} style={{ marginBottom: '1rem', width: '100%', cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: '24px', fontWeight: '400', color: '#FFFFFF' }}>
                    <span> Cloud PBX Phone Systems</span>
                    <span>{openId === 1 ? '-' : '+'}</span>
                  </div>
                </div>
                <Collapse isOpen={openId === 1}>
                  <Card>
                    <CardBody>
                      <ul className='bullet-remove'>
                        <li>We provide access to leading cloud PBX providers, offering a wide range of phone systems to suit your specific requirements.</li>
                      </ul>

                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />
              </div>

              <div>
                <div color="primary" onClick={() => toggle(2)} style={{ marginBottom: '1rem', width: '100%', cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: '24px', fontWeight: '400', color: '#FFFFFF' }}>
                    <span>  Cloud PBX Consulting</span>
                    <span> {openId === 2 ? '-' : '+'}</span>
                  </div>

                </div>
                <Collapse isOpen={openId === 2}>
                  <Card>
                    <CardBody>
                      <ul className='bullet-remove'>
                        <li>Our expert team can assist you in selecting the ideal cloud PBX solution for your business, ensuring it aligns with your communication goals and budget.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />
              </div>

              <div>
                <div color="primary" onClick={() => toggle(3)} style={{ marginBottom: '1rem', width: '100%', cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: '24px', fontWeight: '400', color: '#FFFFFF' }}>
                    <span> Cloud PBX Implementation</span>
                    <span> {openId === 3 ? '-' : '+'}</span>
                  </div>

                </div>
                <Collapse isOpen={openId === 3}>
                  <Card>
                    <CardBody>
                      <ul className='bullet-remove'>
                        <li>We handle the seamless installation and configuration of your cloud PBX system, ensuring a smooth transition to cloud-based telephony.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
                <div style={{
                  // backgroundColor: 'white',
                  width: '100%',
                  border: '1px solid',
                  borderImageSource: 'linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.2) 49.06%, rgba(245, 245, 245, 0) 100%)',
                  borderImageSlice: 1
                }} />
              </div>

              <div>
                <div color="black" onClick={() => toggle(4)} style={{ marginBottom: '1rem', width: '100%', cursor: 'pointer', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: '24px', fontWeight: '400', color: '#FFFFFF' }}>
                    <span style={{ fontSize: '24px' }}>  Cloud PBX Support</span>
                    <span style={{ fontSize: '24px' }}> {openId === 4 ? '-' : '+'}</span>
                  </div>
                </div>
                <Collapse isOpen={openId === 4}>
                  <Card>
                    <CardBody>
                      <ul className='bullet-remove'>
                        <li>Our ongoing support services guarantee that your cloud PBX system remains functional and optimized, providing assistance whenever you need it.</li>
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div>

            {/* Box 2 */}
            <div className='CP-sixth-box2'>
              <img
                alt="..."
                src={require("assets/img/Landing Page 1 (2).png")}
                style={{
                  width: '95%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </div>
          </div>
        </div>
        <div className='CC-partner-section-img1'
       >
          <img
            style={{
              width: '43%',
              height: '50%',
            }}
            alt="..."
            src={require("assets/img/Vector.svg").default}
          />
        </div>
        <div className='CC-partner-section-img2'>
        <img
          style={{
            width: '10%',
            height: '50%',
            margin: '-36% 0% 0% 0%'

          }}
          alt="..."
          src={require("assets/img/Ellipse 7.svg").default}

        />
      </div>

      <div className='CC-partner-section-img3'>
        <img
          style={{
            width: '15%',
            height: '50%',
             margin: '-33% 0% 0% 0%'
          }}
          alt="..."
          src={require("assets/img/Ellipse 6.svg").default}
        />
      </div>


      <div className="CC-Partner-Section">
        <div className="CC-first-section">

          <h1>
            Become a Partner
          </h1>
          <div className="glassmorphism" style={{ width: '82%', padding: '20px' }} >
            <p style={{ textAlign: 'start', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '400' }}>
              We're actively pursuing partnerships on a global scale. Collaborating with us means forging a strategic alliance aimed at revolutionizing customer experiences and spearheading transformative initiatives for enterprises worldwide. As our esteemed partner, you'll unlock access to state-of-the-art AI technology and a dedicated team fully devoted to your triumph. Together, we'll co-create tailor-made solutions grounded in feedback, unleashing the full potential of your brand, elevating customer satisfaction to unprecedented levels, and driving substantial revenue growth.
            </p>
            <button className="button-bts bts" onClick={handleClick} >
              <img alt="Handshake icon" className="path" src={require("assets/img/Vector (5).svg").default} />
              <span style={{ marginLeft: '5px', fontFamily: 'Space Grotesk', fontSize: '20px', fontWeight: '500', color: 'rgba(255, 255, 255, 0.8)' }}>Become a Partner</span>
            </button>
          </div>
        </div>

        <div className="second-section">
          <img
            alt="..."
            src={require("assets/img/Frame 147.svg").default}
            style={{
              width: '100%',
              height: '60%',
              objectFit: 'cover',
              borderRadius: '20px'
            }}
          />
        </div>
      </div>
      <div className='Explore-main'>
        <div className='Explore-first'>
          <button className='Explore-btn'

          >
            OUR PRODUCTS
          </button>

          <h1 className='explore-h1'>Explore Our <span style={{ fontFamily: 'Playfair Display' }}>Other</span>  Product</h1>
        </div>


        {/* Box 1 */}
        <div className='explore-box1'>

          <img
            alt="..."
            src={require("assets/img/AI-agent1.svg").default}
            onClick={navigateAi}
            className='box1-img'
          />

        </div>

        <div className='explore-box2'>
          {/* Box 1 */}
          <div className='box2-img1'>

            <img
              className='img1-a'
              alt="Box 2 Image"
              src={require("assets/img/Sentiment.svg").default}
              onClick={sentimentAnalysis}
            />
          </div>

          {/* Box 2 */}
          <div className='box2-img2'>
            <img
              className='img2-a'
              alt="Box 1 Image"
              src={require("assets/img/Generative AI.svg").default}
              onClick={generativeAi}
            />
          </div>
        </div>

      </div>
        <Footer />
      </div>
    </>
  );
}

export default CloudPBX
